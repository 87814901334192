<div class="container-fluid">

  <div class="page-header-container container">
    <h2>{{'carrier_dashboard.all_shipments' | translate}}</h2>

    <div class="buttons-container">
      <!-- TODO: translate -->
      <button class="btn-primary green primary-hover-animation" mat-flat-button color="primary" (click)="export()">
        Export
      </button>
      <!-- TODO: translate -->
      <a matTooltip="Add a shipment" mat-icon-button color="primary" [routerLink]="['/main/carrier/wizard']">
        <mat-icon>add_circle</mat-icon>
      </a>
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
      <a mat-icon-button color="primary" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
    </div>

  </div>

      <div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
        <div class="alert alert-info">{{'carrier_dashboard.shipments.no-shipment' | translate}} </div>
      </div>

      <table class="table table-custom" style="width: 100%" *ngIf="!!shipments && shipments.length > 0">
        <thead>
          <td>{{'common.number' | translate}}</td>
          <td>{{'common.client' | translate}}</td>
          <td>{{'common.from' | translate}}</td>
          <td>{{'common.to' | translate}}</td>
          <td>{{'common.status' | translate}}</td>
        </thead>

        <tbody>
          <tr *ngFor="let shipment of shipments">
            <td [attr.data-label]="'common.number' | translate">
              <span>
                {{shipment.number | shipmentNumberPipe }}
              </span>
            </td>
            <td>
              <ul class="co-simple-list" *ngIf="shipment.client_object">
                <li>
                  <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details"><strong>{{shipment.client_object.company}}</strong></a>
                </li>
                <li>
                  <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                    {{shipment.client_object.first_name }}
                    {{shipment.client_object.last_name }}
                  </a>
                </li>
              </ul>
            </td>
            <td [attr.data-label]="'common.from' | translate">
              <span *ngIf="shipment.pickup_company">
                <strong>{{shipment.pickup_company }}
                <br></strong>

              </span>
              <span *ngIf="shipment.pickup_name">
                {{shipment.pickup_name }}
                <br>
              </span>
              {{shipment.pickup_address }}
              <br>
              {{shipment.pickup_zip }} {{shipment.pickup_location }}
              <br>
              {{shipment.pickup_date }}
            </td>

            <td [attr.data-label]="'common.to' | translate">
              <span *ngIf="shipment.delivery_company">
                <strong>{{shipment.delivery_company }}</strong>
                <br> </span>
              <span *ngIf="shipment.delivery_name">
                {{shipment.delivery_name }}
                <br> </span>
              {{shipment.delivery_address }}
              <br>
              {{shipment.delivery_zip }} {{shipment.delivery_location }}
              <br>
              {{shipment.delivery_date }}
            </td>
            <td [attr.data-label]="'common.status' | translate">
              {{shipment.status | shipmentStatusPipe}}
            </td>
            <td>
              <!-- TODO: translate -->
              <button matTooltip="More information"
                class="btn-primary blue big-icon mobile-text primary-hover-animation ms-auto"
                routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                <mat-icon>info</mat-icon>
                <span>Info</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

</div>
