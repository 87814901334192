<div class="container-fluid mt-1">
  <div class="page-header-container container">
    <h2>{{'carrier_dashboard.ads.main_title' | translate}}</h2>

    <div class="buttons-container">
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
      <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
    </div>

  </div>
  <div class="row">
    <div class="col-12">
      <div class="text-center mt-5" *ngIf="!isLoading && ads.length == 0">
        <div class="alert alert-info">{{'carrier_dashboard.ads.no_ads' | translate}} </div>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4" *ngFor="let ad of ads">
      <div class="mb-2">
        <app-advertisement-card [advertisement]="ad" (onOpenDetails)="onOpenDetails($event)"
          (onOpenEdit)="onOpenEdit($event)"></app-advertisement-card>
      </div>
    </div>
  </div>
</div>
