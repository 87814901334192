import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './pages/public/forget-password/forget-password.component';
import { PublicLayoutComponent } from './pages/public/layout/public/public.component';
import { LoginComponent } from './pages/public/login/login.component';
import { RegisterComponent } from './pages/public/register/register.component';
import { ResetPasswordComponent } from './pages/public/reset-password/reset-password.component';
import { CarrierDashboardComponent } from './pages/carrier/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { AboutComponent } from './pages/carrier/about/about.component';
import { ClientsOverviewComponent } from './pages/carrier/client-overview/client-overview.component';
import { ProfileComponent } from './pages/carrier/profile/profile.component';
import { OrganizationDetailsComponent } from './pages/carrier/organization-details/organization-details.component';
import { UserManagementComponent } from './pages/carrier/user-management/user-management.component';
import { WareHouseManagementComponent } from './pages/carrier/warehouse-management/warehouse-management.component';
import { PipelineDispatchComponent } from './pages/carrier/pipeline-dispatch/pipeline-dispatch.component';
import { PipelineReleaseComponent } from './pages/carrier/pipeline-release/pipeline-release.component';
import { EmptiesComponent } from './pages/carrier/empties/empties.component';
import { SettingsComponent } from './pages/carrier/settings/settings.component';
import { FacturationComponent } from './pages/carrier/facturation/accounting/accounting.component';
import { ActivityComponent } from './pages/carrier/activity/activity.component';
import { AlertsComponent } from './pages/carrier/alerts/alerts.component';
import { MarketPlaceComponent } from './pages/carrier/market-place/market-place.component';
import { ShipmentDetailsComponent } from './pages/carrier/shipment-details/shipment-details.component';
import { ShipmentRegistrationComponent } from './pages/carrier/shipment-registration/shipment-registration.component';
import { CarrierSearchComponent } from './pages/carrier/shipment-search/shipment-search.component';
import { ClientDetailsComponent } from './pages/carrier/client-details/client-details.component';
import { ShipmentTrackingPageComponent } from './pages/public/shipment-tracking/shipment-tracking.component';
import { ShipmentEditDeliveryComponent } from './pages/carrier/shipment-edit-delivery/shipment-edit-delivery.component';
import { ShipmentEditPickupComponent } from './pages/carrier/shipment-edit-pickup/shipment-edit-pickup.component';
import { ShipmentEditEmptiesComponent } from './pages/carrier/shipment-edit-empties/shipment-edit-empties.component';
import { ShipmentEditCargoItemsComponent } from './pages/carrier/shipment-edit-cargo-items/shipment-edit-cargo-items.component';
import { ShipmentAssignedComponent } from './pages/driver/shipments-assigned/shipments-assigned.component';
import { ShipmentScannerComponent } from './pages/carrier/shipment-scanner/shipment-scanner.component';
import { DebugComponent } from './pages/carrier/debug/debug.component';
import { ShipmentListComponent } from './pages/carrier/shipment-list/shipment-list.component';
import { ShipmentDoDeliverComponent } from './pages/driver/shipment-do-deliver/shipment-do-deliver.component';
import { ShipmentDoPickupComponent } from './pages/driver/shipment-do-pickup/shipment-do-pickup.component';
import { FleetComponent } from './pages/carrier/fleet/fleet.component';
import { MapsComponent } from './pages/carrier/maps/maps.component';
import { HubsComponent } from './pages/carrier/hubs/hubs.component';
import { PipelineInvoiceComponent } from './pages/carrier/pipeline-invoice/pipeline-invoice.component';
import { AccountingInvoiceEditorComponent } from './pages/carrier/facturation/accounting-invoice-editor/accounting-invoice-editor.component';
import { InvoiceDetailsComponent } from './pages/carrier/facturation/invoice-details/invoice-details.component';
import { UserDetailsComponent } from './pages/carrier/user-details/user-details.component';
import { EmbedTrackTraceComponent } from './pages/embed/track-trace/track-trace.component';
import { EmbedWizardComponent } from './pages/embed/big-wizard/wizard.component';
import { PanelChooserComponent } from './pages/public/panel-chooser/panel-chooser.component';
import { ShipperDashboardComponent } from './pages/shipper/dashboard/dashboard.component';
import { ShipperSearchComponent } from './pages/shipper/shipment-search/shipment-search.component';
import { ShipperMarketPlaceComponent } from './pages/shipper/market-place/market-place.component';
import { ShipperShipmentListComponent } from './pages/shipper/shipment-list/shipment-list.component';
import { ShipperWizardComponent } from './pages/shipper/shipment-wizard/shipment-wizard.component';
import { ShipperDockYardManagementComponent } from './pages/shipper/dock-yard-management/dock-yard-management.component';
import { ShipperShipmentDetailsComponent } from './pages/shipper/shipment-details/shipment-details.component';
import { ClientDetailsShipmentsComponent } from './pages/carrier/client-details-shipments/client-details-shipments.component';
import { ClientDetailsIntegrationsComponent } from './pages/carrier/client-details-integration/client-details-integration.component';
import { ClientWizardComponent } from './pages/client/wizard/client-wizard.component';
import { ClientAuthGuard } from './guards/client-auth.guard';
import { MainComponent } from './pages/main/layout/main/main.component';
import { AdminDashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { AdminAuthGuard } from './guards/admin.guard';
import { AdminOrganizationsComponent } from './pages/admin/organizations/organizations.component';
import { AdminShipmentsComponent } from './pages/admin/shipments/shipments.component';
import { AdminUsersComponent } from './pages/admin/users/users.component';
import { SettingsPlatformPricingComponent } from './pages/carrier/settings-platform-pricing/settings-platform-pricing.component';
import { ClientKeysComponent } from './components/client-keys/client-keys.component';
import { SettingsPlatformSurchargesComponent } from './pages/carrier/settings-platform-surcharges/settings-platform-surcharges.component';
import { CargoFlowForYouComponent } from './pages/carrier/settings-cargoflow-for-you/settings-cargoflow-for-you.component';
import { AdminShipmentSearchComponent } from './pages/admin/shipment-search/shipment-search.component';
import { AdminShipmentDetailsComponent } from './pages/admin/shipment-details/shipment-details.component';
import { ValidateEmailComponent } from './pages/public/validate-email/validate-email.component';
import { DriverDashboardComponent } from './pages/driver/dashboard/dashboard.component';
import { DriverShipmentDetailsComponent } from './pages/driver/shipment-details/shipment-details.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { AdminOrganizationDetailsComponent } from './pages/admin/organization-details/organization-details.component';
import { AdminUserDetailsComponent } from './pages/admin/user-details/user-details.component';
import { AdminAuctionsComponent } from './pages/admin/auctions/auctions.component';
import { AdminTransactionsComponent } from './pages/admin/transactions/transactions.component';
import { AdminActivityComponent } from './pages/admin/activity/activity.component';
import { AdminAdvertisementComponent } from './pages/admin/advertisement/advertisement.component';
import { EmbedSmallWizardComponent } from './pages/embed/small-wizard/wizard.component';
// import { ImportShipmentsComponent } from './pages/carrier/import-shipments/import-shipments.component';
import { TourManagementComponent } from './pages/carrier/tour-management/tour-management.component';
import { TourEditorComponent } from './components/tour-editor/tour-editor.component';
import { ToursArchiveComponent } from './pages/carrier/tours-archive/tours-archive.component';
import { DriverLocationsComponent } from './pages/carrier/driver-locations/driver-locations.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'shipper',
        children: [
          {
            path: 'dashboard',
            component: ShipperDashboardComponent
          },
          {
            path: 'adcard',
            component: AdvertisementComponent
          },
          {
            path: 'about',
            component: AboutComponent
          },
          {
            path: 'search',
            component: ShipperSearchComponent
          },
          {
            path: 'market-place',
            component: ShipperMarketPlaceComponent
          },
          {
            path: 'shipment-list',
            component: ShipperShipmentListComponent
          },
          {
            path: 'shipments/:shipmentUUID',
            data: { panelType: 'shipper' },
            children: [
              {
                path: '',
                component: ShipperShipmentDetailsComponent,
              },
              {
                path: 'edit-pickup',
                component: ShipmentEditPickupComponent,
              },
              {
                path: 'edit-delivery',
                component: ShipmentEditDeliveryComponent,
              },
              {
                path: 'edit-cargo-items',
                component: ShipmentEditCargoItemsComponent,
              },
              {
                path: 'edit-empties',
                component: ShipmentEditEmptiesComponent,
              },
            ]

          },
          {
            path: 'wizard',
            component: ShipperWizardComponent
          },
          {
            path: 'wizard',
            component: ShipperWizardComponent
          },
          {
            path: 'dock-yard-management',
            component: ShipperDockYardManagementComponent
          }

        ]
      },
      {
        path: 'carrier',
        children: [
          {
            path: 'dashboard',
            component: CarrierDashboardComponent
          },
          {
            path: 'shipment-list',
            component: ShipmentListComponent
          },
          {
            path: 'maps',
            component: MapsComponent
          },


          {
            path: 'shipments/:shipmentUUID',
            children: [
              {
                path: '',
                component: ShipmentDetailsComponent,
              },
              {
                path: 'edit-pickup',
                component: ShipmentEditPickupComponent,
              },
              {
                path: 'edit-delivery',
                component: ShipmentEditDeliveryComponent,
              },
              {
                path: 'edit-cargo-items',
                component: ShipmentEditCargoItemsComponent,
              },
              {
                path: 'edit-empties',
                component: ShipmentEditEmptiesComponent,
              },
              {
                path: 'do-pickup',
                component: ShipmentDoPickupComponent,
              },
              {
                path: 'do-deliver',
                component: ShipmentDoDeliverComponent,
              },
              {
                path: '**',
                redirectTo: '',
              }
            ]
          },

          {
            path: 'about',
            component: AboutComponent
          },

          {
            path: 'clients',
            children: [
              {
                path: ':clientUUID/details',
                component: ClientDetailsComponent
              },
              {
                path: ':clientUUID/integrations',
                component: ClientDetailsIntegrationsComponent
              },
              {
                path: ':clientUUID/shipments',
                component: ClientDetailsShipmentsComponent
              },
              {
                path: '',
                component: ClientsOverviewComponent
              },
            ]
          },
          {
            path: 'profile',
            component: ProfileComponent
          },
          // {
          //   path: 'import-shipments',
          //   component: ImportShipmentsComponent
          // },
          {
            path: 'pipeline-release',
            component: PipelineReleaseComponent
          },
          {
            path: 'pipeline-dispatch',
            component: PipelineDispatchComponent
          },
          {
            path: 'all-tours',
            component: ToursArchiveComponent
          },
          {
            path: 'tours',
            children: [
              {
                path: '',
                component: TourManagementComponent
              },
              {
                path: ':tourUUID',
                data: { editable: true },
                component: TourEditorComponent
              }
            ]
          },
          {
            path: 'pipeline-invoice',
            component: PipelineInvoiceComponent
          },

          {
            path: 'shipments-assigned/:type',
            component: ShipmentAssignedComponent
          },
          {
            path: 'shipment-scanner',
            component: ShipmentScannerComponent
          },
          {
            path: 'wizard',
            component: ShipmentRegistrationComponent
          },
          {
            path: 'debug',
            component: DebugComponent
          },
          {
            path: 'empties',
            component: EmptiesComponent
          },

          {
            path: 'activity',
            component: ActivityComponent
          },

          {
            path: 'search',
            component: CarrierSearchComponent
          },

          {
            path: 'alerts',
            component: AlertsComponent
          },

          {
            path: 'market-place',
            component: MarketPlaceComponent
          },
          {
            path: 'adcard',
            component: AdvertisementComponent
          },
          {
            path: 'driver-locations/:userUUID',
            component: DriverLocationsComponent
          },
          // {
          //   path: 'export',
          //   component: ExportComponent
          // },
          {
            path: 'settings',
            component: SettingsComponent,
            children: [
              {
                path: 'plugin',
                component: CargoFlowForYouComponent
              },
              {
                path: 'api',
                component: ClientKeysComponent
              },
              {
                path: 'pricing',
                component: SettingsPlatformPricingComponent
              },
              {
                path: 'surcharges',
                component: SettingsPlatformSurchargesComponent
              },
              {
                path: 'fleet',
                component: FleetComponent
              },
              {
                path: 'hubs',
                component: HubsComponent
              },
              {
                path: 'organization',
                component: OrganizationDetailsComponent
              },
              {
                path: 'users',
                children: [
                  {
                    path: '',
                    component: UserManagementComponent
                  },
                  {
                    path: ':userUUID/details',
                    component: UserDetailsComponent
                  },

                ]
              },
              {
                path: '**',
                redirectTo: 'pricing'
              },
            ]
          },
          {
            path: 'facturation',
            children: [
              {
                path: '',
                component: FacturationComponent,
              },
              {
                path: 'invoice-editor/:invoiceUUID',
                component: AccountingInvoiceEditorComponent,
              },
              {
                path: 'invoices/:invoiceUUID',
                component: InvoiceDetailsComponent,
              },
              {
                path: '**',
                redirectTo: ''
              }
            ]
          },
          {
            path: 'warehouse',
            component: WareHouseManagementComponent
          },
          {
            path: '**', redirectTo: 'dashboard',
          }
        ]
      },
      {
        path: 'driver',
        children: [
          {
            path: 'dashboard',
            component: DriverDashboardComponent
          },
          {
            path: 'shipments/:shipmentUUID',
            children: [
              {
                path: '',
                component: DriverShipmentDetailsComponent,
              },
              {
                path: 'do-pickup',
                component: ShipmentDoPickupComponent,
              },
              {
                path: 'do-deliver',
                component: ShipmentDoDeliverComponent,
              },
              {
                path: '**',
                redirectTo: '',
              }
            ]
          },

          {
            path: 'about',
            component: AboutComponent
          },
          {
            path: 'profile',
            component: ProfileComponent
          },
          {
            path: 'shipments-assigned/:type',
            component: ShipmentAssignedComponent
          },
          {
            path: 'shipment-scanner',
            component: ShipmentScannerComponent
          },
          {
            path: 'tours',
            children: [
              {
                path: '',
                data: { onlyForMe: true},
                component: TourManagementComponent
              },
              {
                path: ':tourUUID',
                data: { editable: false },
                component: TourEditorComponent
              }
            ]
          },
          {
            path: 'search',
            component: CarrierSearchComponent
          },
          {
            path: '**', redirectTo: 'dashboard',
          }
        ]
      },
      {
        path: 'admin',
        canActivate: [AdminAuthGuard],
        children: [
          {
            path: 'dashboard',
            component: AdminDashboardComponent
          },
          {
            path: 'adcards',
            component: AdminAdvertisementComponent
          },
          {
            path: 'organizations/:organizationUUID',
            component: AdminOrganizationDetailsComponent,
          },
          {
            path: 'organizations',
            component: AdminOrganizationsComponent,
          },
          {
            path: 'shipments',
            component: AdminShipmentsComponent,
          },
          {
            path: 'shipments/:shipmentUUID',
            children: [
              {
                path: '',
                component: AdminShipmentDetailsComponent,
              },
              {
                path: 'edit-pickup',
                component: ShipmentEditPickupComponent,
              },
              {
                path: 'edit-delivery',
                component: ShipmentEditDeliveryComponent,
              },
              {
                path: 'edit-cargo-items',
                component: ShipmentEditCargoItemsComponent,
              },
              {
                path: 'edit-empties',
                component: ShipmentEditEmptiesComponent,
              },
            ]
          },
          {
            path: 'users',
            component: AdminUsersComponent
          },
          {
            path: 'users/:userUUID',
            component: AdminUserDetailsComponent
          },

          {
            path: 'auctions',
            component: AdminAuctionsComponent
          },




          // {
          //   path: 'auctions/:uuid',
          //   component: AdminUserDetailsComponent
          // },

          {
            path: 'transactions',
            component: AdminTransactionsComponent
          },
          // {
          //   path: 'transactions/:uuid',
          //   component: AdminUserDetailsComponent
          // },


          {
            path: 'shipment-search',
            component: AdminShipmentSearchComponent
          },
          {
            path: 'activity',
            component: AdminActivityComponent
          },

          {
            path: '**',
            redirectTo: 'dashboard'
          }
        ]
      }
    ]
  },
  {
    path: 'public',
    children: [
      {
        path: 'shipment-tracking/:tracking_uuid',
        component: ShipmentTrackingPageComponent
      },
    ]
  },
  {
    path: 'client',
    canActivate: [ClientAuthGuard],
    children: [
      {
        path: 'wizard', component: ClientWizardComponent,
      }
    ]
  },
  {
    path: 'embed',
    children: [
      {
        path: 'big-wizard', component: EmbedWizardComponent,
      },
      {
        path: 'small-wizard', component: EmbedSmallWizardComponent,
      },
      {
        path: 'track-trace', component: EmbedTrackTraceComponent,
      },
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'validate-email',
        component: ValidateEmailComponent
      },
      {
        path: 'panel-chooser',
        component: PanelChooserComponent,
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'forgot-password',
        component: ForgetPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },

      {
        path: '**',
        redirectTo: 'login'
      }
    ]
  },
  {
    path: '**', redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
