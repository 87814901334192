<div class="container-fluid">

    <div class="page-header-container container">
        <h2>{{'common.search' | translate}}</h2>
    </div>

    <form class="search-form position-relative" [formGroup]="searchForm">

        <div class="mb-3 search-bar">
            <mat-icon>search</mat-icon>
            <input type="text" class="form-control" id="exampleFormControlInput1" formControlName="search"
                [placeholder]="'common.main_search_bar' | translate">
        </div>

        <div class="spinner-border" role="status" *ngIf="isLoading">
        </div>

    </form>

    <div *ngIf="searchForm.value.search != '' && !foundShipments || foundShipments?.length === 0"
        class="alert alert-info w-100">
        {{'common.no_shipments_found' | translate}}
    </div>

    <table class="table table-custom" style="width: 100%" *ngIf="!!foundShipments && foundShipments.length > 0">
        <thead>
            <tr>
                <th scope="col">{{'common.number' | translate}} / Status</th>
                <th scope="col">{{'common.pick-up-location' | translate}}</th>
                <th scope="col">{{'common.delivery_location' | translate}}</th>
                <th></th>
            </tr>

        </thead>

        <tbody>
            <tr *ngFor="let foundShipment of foundShipments">
                <td [attr.data-label]="'common.number' | translate">
                    <ul class="co-simple-list">
                        <li>
                            <span>
                                <strong>{{ foundShipment.number | shipmentNumberPipe}}</strong>
                            </span>
                        </li>
                        <li>{{ foundShipment.status | shipmentStatusPipe}}</li>
                    </ul>
                </td>
                <td [attr.data-label]="'common.pick-up-location' | translate">
                    <ul class="co-simple-list">
                        <li><strong>{{ foundShipment.pickup_company}}</strong></li>
                        <li>{{ foundShipment.pickup_name}}</li>
                        <li>{{ foundShipment.pickup_address}}</li>
                        <li>{{ foundShipment.pickup_location}}</li>
                    </ul>
                </td>
                <td [attr.data-label]="'common.delivery_location' | translate">
                    <ul class="co-simple-list">
                        <li><strong>{{ foundShipment.delivery_company}}</strong></li>
                        <li>{{ foundShipment.delivery_name}}</li>
                        <li>{{ foundShipment.delivery_address}}</li>
                        <li>{{ foundShipment.delivery_location}}</li>
                    </ul>
                </td>
                <td>
                    <!-- TODO: translate -->
                    <button matTooltip="More information"
                    class="btn-primary blue big-icon mobile-text primary-hover-animation ms-auto"
                    routerLink="/main/shipper/shipments/{{foundShipment.uuid}}">
                    <mat-icon>info</mat-icon>
                    <span>Info</span>
                  </button>
                  </td>

            </tr>
        </tbody>
    </table>

    <!-- <pre>{{foundShipments | json}}</pre> -->

</div>