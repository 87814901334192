<div class="container-fluid">

  <div class="page-header-container d-flex flex-row">
    <div class="w-100">
      <h2>{{'carrier_dashboard.manage_active_tours' | translate}}</h2>
    </div>

    <div class="buttons-container" style="width: auto;">
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
      <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
      <!-- TODO: translate tooltips -->
      <a matTooltip="Archived tours" class="btn-primary green big-icon primary-hover-animation" routerLink="/main/carrier/all-tours" *ngIf="!onlyForThisDriver">
        <mat-icon>archive</mat-icon>
      </a>

      <!-- TODO: translate tooltips -->
      <button matTooltip="Duplicate tour" class="btn-primary green big-icon primary-hover-animation" (click)="duplicate()" [disabled]="this.selectedItems.length == 0"
        *ngIf="!onlyForThisDriver">
        <mat-icon>auto_awesome_motion</mat-icon>
      </button>

      <!-- TODO: translate tooltips -->
      <button matTooltip="Add a tour" class="btn-primary green big-icon primary-hover-animation" (click)="add()" *ngIf="!onlyForThisDriver">
        <mat-icon>add</mat-icon>
      </button>

    </div>

  </div>

  <app-filtered-tags (onChangeSelectionEvent)="onChangeSelectionEvent($event)"
    (onUpdateFilterEvent)="onChangeFilterEvent($event)" [filterProperties]="filterProperties"
    *ngIf="!onlyForThisDriver"> </app-filtered-tags>

  <div class="text-center mt-5" *ngIf="!isLoading && filteredItems.length == 0">
    <div class="alert alert-info">{{'carrier_dashboard.no_tours_to_manage' | translate}}</div>

    <button [matTooltip]="'common.create_tours_for_all' | translate" class="btn-primary green big-icon mobile-text primary-hover-animation mx-auto" (click)="createToursForVehicles()">
      <mat-icon>drive_eta</mat-icon>
      <span>{{'common.create_tours_for_all' | translate}}</span>
    </button>

  </div>

  <div *ngIf="!!filteredItems && filteredItems.length > 0">
    <div class="scrollable-list">
      <table class="table table-custom w-100" >
        <thead>
          <td *ngIf="!onlyForThisDriver"></td>
          <td>{{'common.id' | translate}}</td>
          <td>{{'common.status' | translate}}</td>
          <td>{{'common.date' | translate}}</td>
          <td>{{'common.vehicle' | translate}}</td>
          <td>{{'common.driver'| translate}}</td>
          <td>{{'common.weight' | translate}}</td>
          <td>{{'carrier_dashboard.number_of_slots' | translate}}</td>
          <td>{{'carrier_dashboard.number_of_stops' | translate}}</td>
          <td>{{'carrier_dashboard.travel_time' | translate}}</td>
          <td *ngIf="!onlyForThisDriver">{{'common.revenue' | translate}}</td>
          <td *ngIf="!onlyForThisDriver">{{'common.profit' | translate}}</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let tour of filteredItems">
            <td [attr.data-label]="'common.select' | translate" *ngIf="!onlyForThisDriver">
              <mat-checkbox (change)="onToggleSelection($event, tour)"
                [checked]="isShipmentChecked(tour)"></mat-checkbox>
            </td>
            <td [attr.data-label]="'common.id' | translate">
              {{tour.id }}
            </td>
            <td [attr.data-label]="'common.status' | translate">
              {{tour.status | tourStatusPipe }}
            </td>
            <td [attr.data-label]="'common.date' | translate">
              {{tour.date | date: 'dd-MM-yyyy'}}
            </td>
            <td [attr.data-label]="'common.vehicle' | translate">
              {{tour.vehicle_object?.number}}
            </td>
            <td [attr.data-label]="'common.driver' | translate">
              <span *ngIf="tour.driver_object?.first_name">{{tour.driver_object?.first_name }}
                {{tour.driver_object?.last_name}}</span>
              <span style="color: lightslategray;"
                *ngIf="!tour.driver_object?.first_name">{{'common.no_driver_assingned' | translate}}</span>
            </td>
            <td [attr.data-label]="'common.weight' | translate">
              {{tour.weight | number: "1.0-2"}} Kg
            </td>

            <td [attr.data-label]="'carrier_dashboard.number_of_slots' | translate">
              {{tour.ground_spaces | number: "1.2-2"}}
            </td>

            <td [attr.data-label]="'carrier_dashboard.number_of_stops' | translate">
              {{tour.number_of_stops}}
            </td>

            <td [attr.data-label]="'carrier_dashboard.travel_time' | translate">
              {{tour.driving_duration }}
            </td>

            <td [attr.data-label]="'common.revenue' | translate" *ngIf="!onlyForThisDriver">
              {{tour.turnover | number: "1.2-2"}}
            </td>

            <td [attr.data-label]="'common.profit' | translate" *ngIf="!onlyForThisDriver">
              {{tour.profit | number: "1.2-2" }}
            </td>

            <td class="d-flex justify-content-end gap-2">
              <a class="btn-primary dark small-icon" routerLink="/main/driver/tours/{{tour.uuid}}"
                *ngIf="onlyForThisDriver">
                <mat-icon>info</mat-icon>
                {{'common.details' | translate}}
              </a>

              <!-- TODO: translate -->
              <a matTooltip="More information" class="btn-primary blue big-icon mobile-text primary-hover-animation" routerLink="/main/carrier/tours/{{tour.uuid}}"
                *ngIf="!onlyForThisDriver">
                <mat-icon>info</mat-icon>
                <span>Info</span>
              </a>

              <!-- TODO: translate -->
              <button matTooltip="Delete tour" class="btn-primary red big-icon mobile-text primary-hover-animation" (click)="onDelete(tour)"
                *ngIf="!onlyForThisDriver">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>