<mat-card class="mt-3 mx-2">
  <mat-card-content>

    <div class="mb-2" style="display: flex; flex-direction: row; justify-content: space-between;">
      <h1 class="mb-0">{{'carrier_dashboard.import_pipeline.main-heading' | translate}}</h1>
      <div class="d-flex gap-2">
        <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
        <button class="btn-primary green small-icon" (click)="help()">
          <mat-icon>info</mat-icon>
        </button>
        <button class="btn-primary green small-icon" (click)="refresh()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>

    <!-- <pre>{{shipments | json}}</pre> -->

    <div class="col-8 rmpm dropzone h-100" appDrag (files)="filesDropped($event)">
      <div class="text-wrapper h-100">
        <div class="d-flex flex-column align-items-center justify-content-center h-100">
          <h4>{{'carrier_dashboard.import_pipeline.drag_excel_here' | translate}}</h4>
          <div class="">
            <button class="green-btn primary-hover-animation w-max" (click)="fileUpload.click()" [disabled]="isLoading">
              {{'carrier_dashboard.import_pipeline.or_press_here' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <input type="file" class="file-input" style="display: none;" (change)="onFileSelected($event)" #fileUpload>

    <button mat-dialog-close class="btn-primary yellow primary-hover-animation ms-auto mt-2 mb-2 align-items-center">{{'common.close'| translate}}</button>

    <div class="alert alert-info bg-green-light mt-2 mb-2 border-0" *ngIf="!!lastImport?.shipments && lastImport?.shipments?.length">
      <span *ngIf="lastImport.shipments.length == 1">
        {{lastImport.shipments.length}} Sendung importiert {{lastImport.created_at | humanDatePipe}}
      </span>
      <span *ngIf="lastImport.shipments.length > 1">
        {{lastImport.shipments.length}} {{'carrier_dashboard.import_pipeline.shipments_imported' | translate }}
      </span>
    </div>

    <table class="table table-custom" style="width: 100%"
      *ngIf="!!lastImport?.shipments && lastImport?.shipments?.length">
      <thead>
        <tr>
          <th>{{'common.number' | translate}}</th>
          <th>{{'common.client' | translate}}</th>
          <th>{{'common.price' | translate}}</th>
          <th>{{'common.from' | translate}}</th>
          <th>{{'common.to' | translate}}</th>
          <th>{{'common.details' | translate}}</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let shipment of lastImport.shipments">
          <td [attr.data-label]="'common.number' | translate" [attr.name]="'common.number' | translate">
            <a routerLink="/main/carrier/shipments/{{shipment.uuid}}">
              {{shipment.number | shipmentNumberPipe }}
            </a>
          </td>
          <td [attr.data-label]="'common.client' | translate">
            <ul class="co-simple-list" *ngIf="shipment.client_object">
              <li>
                <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                  <span *ngIf="shipment.client_object.company; else noClient">
                    {{shipment.client_object.company }}
                  </span>
                  <ng-template #noClient>
                    {{shipment.client_object.first_name }}
                    {{shipment.client_object.last_name }}
                  </ng-template>
                </a>
              </li>
              <li>
                <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                </a>
              </li>
            </ul>
          </td>
          <td [attr.data-label]="'common.price' | translate" [attr.name]="'common.price' | translate">
            <span *ngIf="ownOrganization?.default_currency === 'CHF'">{{ownOrganization?.default_currency}}</span> {{shipment.total_price | pricePipe}} <span *ngIf="ownOrganization?.default_currency !== 'CHF'">{{ownOrganization?.default_currency}}</span>
          </td>
          <td [attr.data-label]="'common.from' | translate" [attr.name]="'common.pickup_address' | translate">
            {{shipment.pickup_address }}
            <br>
            {{shipment.pickup_zip }} {{shipment.pickup_location }}
            <br>
            {{shipment.pickup_date }}
          </td>

          <td [attr.data-label]="'common.to' | translate" [attr.name]="'common.delivery_address' | translate">
            {{shipment.delivery_address }}
            <br>
            {{shipment.delivery_zip }} {{shipment.delivery_location }}
            <br>
            {{shipment.delivery_date }}
          </td>


          <td [attr.data-label]="'common.details' | translate" [attr.name]="'common.details' | translate">
            {{'common.total_weight' | translate}} {{shipment.total_weight | pricePipe }} Kg
            <br>
            <span *ngIf="shipment.total_ground_spaces">
              {{'common.ground_spaces' | translate}} {{shipment.total_ground_spaces }}
            </span>
          </td>

        </tr>
      </tbody>
    </table>

    <!-- <a routerLink="/main/carrier/pipeline-dispatch" class="btn btn-link w-100">{{'common.disposition' | translate}}</a> -->

  </mat-card-content>
</mat-card>
