import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { environment } from 'projects/webapp/app/environments/environment';
import { ICarrierShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { LocationService } from 'projects/webapp/app/services/location.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit {
  formGroup: FormGroup;
  pins: any[] = [];
  accessToken: string = environment.mapboxToken;
  driverLocationInterval: any;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public locationService: LocationService,
    public carrierShipmentsService: CarrierShipmentsService,
    public dialog: MatDialog,
    private http: HttpClient,
  ) {
    this.formGroup = new FormGroup({
      current_driver_locations: new FormControl(true),
      current_shipments_pickup_locations: new FormControl(false),
      current_shipments_delivery_locations: new FormControl(false),
    });
  }

  ngOnInit(): void {
    this.updatePins();

    this.driverLocationInterval = setInterval(() => {
      this.updateDriverLocations();

    }, 300000); // 300000 ms = 5 minutes

    this.formGroup.valueChanges.subscribe(() => {
      this.updatePins();
    });
    console.log('MapComponent Pins:', this.pins);
  }

  // async refresh(){
  //   try {
  //     this.pins = await lastValueFrom(this.locationService.getRecentDriverLocations());
  //   } catch (error) {
  //     console.error('Error fetching locations', error);
  //   }
  // }

  async updateDriverLocations(): Promise<void> {
    if (this.formGroup.value.current_driver_locations) {
      try {
        const driverPins = await this.fetchDriverLocations();
        console.log('Fetched driver locations:', driverPins);
        this.pins = this.pins.filter(pin => pin.title !== 'Driver Location'); // Remove old driver locations
        this.pins.push(...driverPins); // Add updated driver locations
        console.log('Updated driver locations:', driverPins);
      } catch (error) {
        console.error('Error updating driver locations:', error);
      }
    }
  }

  async updatePins(): Promise<void> {
    const pins: any[] = []; // Reset pins

    const {
      current_driver_locations,
      current_shipments_pickup_locations,
      current_shipments_delivery_locations,
    } = this.formGroup.value;

    if (current_driver_locations) {
      const driverPins = await this.fetchDriverLocations();
      pins.push(...driverPins);
    }
    if (current_shipments_pickup_locations) {
      const pickupPins = await this.fetchPickupLocations();
      pins.push(...pickupPins);
    }

    if (current_shipments_delivery_locations) {
      const deliveryPins = await this.fetchDeliveryLocations();
      pins.push(...deliveryPins);
    }

    this.pins = pins;
  }

  async fetchDriverLocations(): Promise<any[]> {
    try {
      const driverLocations = await lastValueFrom(
        this.locationService.getRecentDriverLocations()
      );
      console.log(driverLocations, 'get drivers')
      return driverLocations.map((location: any) => ({
        lng: location.lng,
        lat: location.lat,
        title: "Driver Location",
        first_name: location.user_object.first_name,
        last_name: location.user_object.last_name,
      }));
    } catch (error) {
      console.error('Error fetching driver locations', error);
      return [];
    }
  }

  async fetchPickupLocations(): Promise<any[]> {
    try {
      // Hardcoded pickup address in Switzerland
      const pickupAddress = 'Zurich Main Station, Bahnhofpl., 8001 Zurich, Switzerland';

      const coords = await this.geocodeAddress(pickupAddress);  // Use the address for geocoding
      return [{
        lng: coords.lng,
        lat: coords.lat,
        title: "Pickup Location",
        address: `Pickup: ${pickupAddress}`,
      }];

    } catch (error) {
      console.error('Error fetching pickup locations', error);
      return [];
    }
  }

  async fetchDeliveryLocations(): Promise<any[]> {
    try {
      // Hardcoded delivery address in Switzerland
      const deliveryAddress = 'Geneva International Airport, Route de l\'Aéroport, 1215 Genève, Switzerland';

      const coords = await this.geocodeAddress(deliveryAddress);  // Use the address for geocoding
      return [{
        lng: coords.lng,
        lat: coords.lat,
        title: "Delivery Location",
        address: `Delivery: ${deliveryAddress}`,
      }];

    } catch (error) {
      console.error('Error fetching delivery locations', error);
      return [];
    }
  }

  async geocodeAddress(address: string): Promise<{ lng: number, lat: number }> {
    try {
      const response = await lastValueFrom(this.http.get<any>(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${this.accessToken}`
      ));
      if (response.features && response.features.length > 0) {
        const [lng, lat] = response.features[0].center;  // Extract lng, lat from the first result
        return { lng, lat };
      } else {
        throw new Error('No coordinates found for the given address');
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      return { lng: 0, lat: 0 };  // Return a default value in case of an error
    }
  }

}
