<div class="container driver-view-bottom">
    <mat-card>
        <mat-card-content *ngIf="shipment">
            <div class="page-header-container container d-flex flex-row">
                <div class="w-100">
                    <h2>{{'common.shipment_details' | translate}}: {{shipment.number | shipmentNumberPipe}}</h2>
                </div>

                <div class="buttons-container" style="width: auto;">

                    <a mat-icon-button color="primary" routerLink="/public/shipment-tracking/{{shipment.tracking_uuid}}"
                        target="_blank">
                        <mat-icon>route</mat-icon>
                    </a>

                    <a mat-icon-button color="primary" [routerLink]="" (click)="share()" *ngIf="hasShare">
                        <mat-icon>share</mat-icon>
                    </a>
                </div>
            </div>

            <div class="my-3 d-flex gap-4 w-100">
                <button class="btn-primary green primary-hover-animation d-flex gap-2 w-100 w-md-fit"
                    (click)="downloadDeliverySlip()"><mat-icon>file_copy</mat-icon> {{'common.delivery_slip' |
                    translate}}</button>
                <button class="btn-primary green primary-hover-animation d-flex gap-2 w-100 w-md-fit"
                    (click)="downloadLabel()"><mat-icon>file_copy</mat-icon> {{'common.label' | translate}}
                </button>
            </div>

            <hr>

            <ul class="co-simple-list">

                <!-- TODO: add here source (api, public wizard, private wizard, market place etc.) -->

                <li *ngIf="shipment.custom_number">{{'common.custom_number' | translate}}: {{shipment.custom_number}}
                </li>

                <li><span>{{'common.created' | translate}}:</span> <span>{{shipment.created_at | humanDatePipe}}</span>
                </li>
                <li><span>{{'common.last_updated' | translate}}:</span> <span>{{shipment.updated_at |
                        humanDatePipe}}</span></li>
                <li><span>{{'common.current_location' | translate}}:</span> <span>{{shipment.current_loc |
                        shipmentCurrentLocationStatusPipe}}</span></li>
                <!-- <li>{{'common.task' | translate}}: {{shipment.current_loc | shipmentCurrentLocationStatusPipe}}</li> -->
                <li *ngIf="shipment.assigned_driver_object">
                    <span>
                        {{'common.current_driver' | translate}}:&nbsp;
                    </span>
                    <a routerLink="/main/carrier/settings/users/{{shipment.assigned_driver_object.uuid}}/details">
                        {{shipment.assigned_driver_object.first_name}}
                        {{shipment.assigned_driver_object.last_name}}
                    </a>
                </li>
                <li *ngIf="shipment.assigned_driver_object">
                    <span>{{'common.task' | translate}}:</span> <span>{{shipment.task | shipmentTaskStatusPipe }}</span>
                </li>
                <li>
                    <span>{{'common.status' | translate}}:</span> <span>{{shipment.status | shipmentStatusPipe}}</span>

                </li>
                <li *ngIf="shipment.client_object">

                    <span class="me-1">
                        {{'common.client' | translate}}:
                    </span>
                    <a [routerLink]="[]">
                        {{shipment.client_object.company}} {{shipment.client_object.first_name}}
                        {{shipment.client_object.last_name}}
                    </a>
                    <!-- <mat-icon class="mx-2 co-pointer" style="font-size: 20px;"
                            (click)="changeClient()">edit</mat-icon> -->
                </li>

            </ul>
            <hr>
        </mat-card-content>
    </mat-card>

    <app-shipment-address [editable]="false" [shipment]="shipment" [type]="'carrier'"></app-shipment-address>

    <mat-card >
        <mat-card-content *ngIf="shipment">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.cargoes' | translate}}</h5>
                <!-- <a mat-icon-button color="primary"
                    routerLink="/main/carrier/shipments/{{shipment.uuid}}/edit-cargo-items">
                    <mat-icon>edit</mat-icon>
                </a> -->
            </div>

            <ul class="co-simple-list">
                <li><span>{{'common.insured' | translate}}:</span> <span>{{shipment.insurance | yesNoPipe}}</span></li>
                <li><span>{{'common.goods_value' | translate}}:</span> <span>{{ownOrganization?.default_currency}} {{shipment.value}}</span></li>
                <li><span>{{'common.total_weight' | translate}}:</span> <span>{{shipment.total_weight}}kg</span></li>
            </ul>
            <hr>

            <table *ngIf="!!shipment.packets && shipment.packets.length > 0" style="width: 100%"
                class="table table-striped">
                <thead>
                    <td>{{'common.name' | translate}}</td>
                    <td>{{'common.type' | translate}}</td>
                    <td>{{'common.quantity' | translate}}</td>
                    <td>{{'common.mass' | translate}} (cm)</td>
                    <td>{{'common.total_weight' | translate}} (kg)</td>
                </thead>

                <tbody>
                    <tr *ngFor="let packet of shipment.packets">
                        <td [attr.data-label]="'common.name' | translate">{{packet.name}}</td>
                        <td [attr.data-label]="'common.type' | translate">{{packet.type | cargoItemName}}</td>
                        <td [attr.data-label]="'common.quantity' | translate">{{packet.count}}</td>
                        <td [attr.data-label]="'common.mass' | translate">L{{packet.length}}xB{{packet.width}}xH{{packet.height}}</td>
                        <td [attr.data-label]="'common.total_weight' | translate">{{packet.weight}}</td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content>
    </mat-card>

    <mat-card class="mt-3">
        <mat-card-content *ngIf="shipment">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.empty_containers' | translate}}</h5>
            </div>

            <div class="alert alert-info" role="alert" *ngIf="shipment.empties">
                {{'common.empty_container_exchange_ordered' | translate}}
            </div>

            <div class="alert alert-info" role="alert" *ngIf="!shipment.empties">
                <strong>{{'common.none' | translate}}</strong> {{'common.empty_container_exchange_ordered' | translate}}
            </div>

            <table style="width: 100%" class="table table-striped" *ngIf="shipment.empties">
                <thead>
                    <!-- <td></td> -->
                    <td>{{'common.pallets' | translate}}</td>
                    <td>{{'common.frames' | translate}}</td>
                    <td>{{'common.lids' | translate}}</td>
                </thead>

                <tbody>
                    <tr>
                        <!-- <td>Beauftragt</td> -->
                        <td>{{shipment.empties_eur_palets}}</td>
                        <td>{{shipment.empties_sbb_frames}}</td>
                        <td>{{shipment.empties_sbb_covers}}</td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <app-shipment-events *ngIf="shipment && shipment.events" [events]="shipment.events"> </app-shipment-events>

    <app-shipment-images [shipmentUUID]="shipmentUUID"></app-shipment-images>

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.internal_comments' | translate}}</h5>
                <div>
                    <button mat-icon-button color="primary" (click)="refreshPrivateComments()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="onAddPrivateComment()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <ngx-spinner name="privateShipmentComments" [fullScreen]="false"></ngx-spinner>

            <div class="alert alert-info" *ngIf="!privateShipmentComments || privateShipmentComments.length == 0">Noch
                keine Kommentare</div>

            <table style="width: 100%" class="table table-striped"
                *ngIf="privateShipmentComments && privateShipmentComments.length > 0">
                <thead>
                    <td>{{'common.who' | translate}}</td>
                    <td>{{'common.when' | translate}}</td>
                    <td>{{'common.what' | translate}}</td>
                </thead>

                <tbody>

                    <tr *ngFor="let comment of privateShipmentComments">
                        <td>
                            <span *ngIf="comment.author_object">
                                {{comment.author_object.first_name}} {{comment.author_object.last_name}}
                            </span>
                            <span *ngIf="!comment.author_object">Unbekannt</span>
                        </td>
                        <td>{{ comment.updated_at | humanDatePipe }}</td>
                        <td>{{ comment.content }}</td>
                        <td>
                            <button mat-icon-button (click)="onUpdateComment(comment)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="onDeleteComment(comment)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>{{'common.public_comments' | translate}}</h5>
                <div>
                    <button mat-icon-button color="primary" (click)="refreshPublicComments()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="onAddPublicComment()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>

            <ngx-spinner name="publicShipmentComments" [fullScreen]="false"></ngx-spinner>

            <div class="alert alert-info" *ngIf="!publicShipmentComments || publicShipmentComments.length == 0">
                {{'common.no_comments_yet' | translate}}
            </div>

            <table style="width: 100%" class="table table-striped"
                *ngIf="publicShipmentComments && publicShipmentComments.length > 0">
                <thead>
                    <td>{{'common.who' | translate}}</td>
                    <td>{{'common.when' | translate}}</td>
                    <td>{{'common.what' | translate}}</td>
                </thead>

                <tbody>

                    <tr *ngFor="let comment of publicShipmentComments">
                        <td>
                            <span *ngIf="comment.author_object">
                                {{comment.author_object.first_name}} {{comment.author_object.last_name}}
                            </span>
                            <span *ngIf="!comment.author_object">Unbekannt</span>
                        </td>
                        <td>{{ comment.updated_at | humanDatePipe }}</td>
                        <td>{{ comment.content }}</td>
                        <td>
                            <button mat-icon-button (click)="onUpdateComment(comment)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="onDeleteComment(comment)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </mat-card-content>
    </mat-card>

    <!-- <mat-card class="mt-3">
        <mat-card-content *ngIf="shipment">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h5>Label</h5>
            </div>
            <div class="qrcodeImage">
                <qrcode [qrdata]="environment.uiDomain + '/public/shipment-tracking/' + shipment.tracking_uuid"
                    [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </mat-card-content>
    </mat-card> -->

</div>