<div class="row">

  <!-- PICKUP -->
  <div class="col-sm-12 col-lg-6">
    <mat-card>
      <mat-card-content *ngIf="shipment">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="d-flex gap-2">
            <img src="assets/icon/pickup.svg" style="filter: invert(1);" height="28" class="pickup" />
            <h5>{{'common.pickup_information' | translate}}</h5>
          </div>
          <div class="d-flex gap-2">
            <a class="btn-primary dark small-icon" *ngIf="editable"
              routerLink="/main/{{type}}/shipments/{{shipment.uuid}}/edit-pickup">
              <mat-icon>edit</mat-icon>
            </a>
            <button class="btn-primary dark small-icon" (click)="openInGoogleMaps('pickup')">
              <mat-icon>location_on</mat-icon>
            </button>
          </div>
        </div>
        <hr class="w-75 mt-0 opacity-25">

        <p><strong>{{'common.pickup_date' | translate}}:</strong> {{shipment.pickup_date}}</p>
        <!-- <hr> -->

        <ul class="co-simple-list">
          <li *ngIf="!!shipment.pickup_company"><strong>{{shipment.pickup_company}}</strong></li>
          <li>{{shipment.pickup_name}}</li>
          <li>{{shipment.pickup_address}}</li>
          <li>{{shipment.pickup_zip}} {{shipment.pickup_location}}</li>
        </ul>

        <div *ngIf="shipment.pickup_reference">
          <ul class="co-simple-list">
            <li><strong>{{'common.pickup_reference' | translate}}:</strong> {{shipment.pickup_reference}}</li>
          </ul>
        </div>

        <div *ngIf="shipment.pickup_phone" class="d-flex gap-2 align-items-center justify-content-between">
          <span><strong>{{'common.phone' | translate}}:</strong> {{shipment.pickup_phone}}</span>
          <button class="btn-primary dark primary-hover-animation">
            <a class="d-flex justify-content-center align-items-center gap-2 text-white text-decoration-none"
              href="tel:{{shipment.pickup_phone}}" *ngIf="shipment.pickup_phone">
              <mat-icon>phone</mat-icon>
            </a>
          </button>
        </div>


        <div class="alert alert-info mt-2" *ngIf="shipment.pickup_notification">
          {{'common.telephone_notification' | translate}}
        </div>

        <div class="alert alert-info" *ngIf="shipment.pickup_crane_needed">
          {{'common.crane_needed_at_pickup' | translate}}
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- DELIVER -->
  <div class="col-sm-12 col-lg-6">
    <mat-card>
      <mat-card-content *ngIf="shipment">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="d-flex gap-2">
            <img src="assets/icon/delivery.svg" style="filter: invert(1);" height="28" class="deliver" />
            <h5 style="word-break: break-all;">{{'common.delivery_information' | translate}}</h5>
          </div>
          <div class="d-flex gap-2">
            <a class="btn-primary dark small-icon" *ngIf="editable"
              routerLink="/main/{{type}}/shipments/{{shipment.uuid}}/edit-delivery">
              <mat-icon>edit</mat-icon>
            </a>
            <button class="btn-primary dark small-icon" (click)="openInGoogleMaps('delivery')">
              <mat-icon>location_on</mat-icon>
            </button>
          </div>
        </div>
        <hr class="w-75 mt-0 opacity-25">

        <p><strong>{{'common.delivery_date' | translate}}:</strong> {{shipment.delivery_date}}</p>
        <!-- <hr> -->
        <ul class="co-simple-list">
          <li *ngIf="!!shipment.delivery_company"><strong>{{shipment.delivery_company}}</strong></li>
          <li>{{shipment.delivery_name}}</li>
          <li>{{shipment.delivery_address}}</li>
          <li>{{shipment.delivery_zip}} {{shipment.delivery_location}}</li>
        </ul>

        <div *ngIf="shipment.delivery_reference">
          <hr>
          <ul class="co-simple-list">
            <li><strong>{{'common.delivery_reference' | translate}}:</strong> {{shipment.delivery_reference}}</li>
          </ul>
        </div>

        <div *ngIf="shipment.delivery_phone" class="d-flex gap-2 align-items-center justify-content-between">
          <span><strong>{{'common.phone' | translate}}:</strong> {{shipment.delivery_phone}}</span>
          <button class="btn-primary dark primary-hover-animation">
            <a class="d-flex justify-content-center align-items-center gap-2 text-white text-decoration-none"
              href="tel:{{shipment.delivery_phone}}">
              <mat-icon>phone</mat-icon>
            </a>
          </button>
        </div>

        <div class="alert alert-info mt-2" *ngIf="shipment.delivery_crane_needed">
          {{'common.crane_needed_at_delivery' | translate}}
        </div>

      </mat-card-content>
    </mat-card>
  </div>

</div>