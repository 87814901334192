import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IDetailedUser, UsersService, IUser } from 'projects/webapp/app/services/users.service';
import { deepCopy } from 'projects/webapp/app/utils';
import { Router } from '@angular/router';
import { UserEditorComponent, UserPasswordEditorComponent } from 'projects/webapp/app/components/user-editor/user-editor.component';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {


  currentOrg = '';
  users: IDetailedUser[] = [];

  constructor(
    public authService: AuthService,
    public usersService: UsersService,
    private title: Title,
    private dialog: MatDialog,
    private router: Router,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.user_management').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.currentOrg = this.authService.getCurrentOrganizationName() || '';
    this.refresh();
  }

  async refresh() {
    try {
      this.spinnerService.show();
      this.users = await this.usersService.getOrgUsers().toPromise() as any;
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.hide();
    }
  }

  getFullName(user: IUser){
    return `${user.first_name} ${user.last_name}`
  }

  async onDeleteUser(user: IDetailedUser) {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_user_deletion', { first: user.first_name, last: user.last_name }));
    const infoMessage = await lastValueFrom(this.translateService.get('toastr.user_deleted'));

    console.log(`going to delete userId: ${user.first_name} ${user.last_name}`)
    if (confirm(confirmMessage)) {
      await this.usersService.deleteUser(user.uuid).toPromise();
      this.toastrService.info(infoMessage);
      this.refresh()
    }
  }

  openUserEditor(data: any) {
    this.dialog.open(UserEditorComponent, { data }).afterClosed().subscribe(async val => {
      if (!!val) {
        if (val.email == '') {
          val.email = null;
        }
        if (val.phone == '') {
          val.phone = null;
        }
        await this.usersService.createUser(val).toPromise();
        this.refresh();
      }
    })
  }

  onAddDriver() {
    const data: IUser & {
      editMode: 'create' | 'update'
    } = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      editMode: 'create',

      permission_pipeline: false,
      permission_dispatch: false,
      permission_invoicing: false,
      permission_client_management: false,
      permission_empties_management: false,
      permission_warehouse_management: false,
      permission_activity: false,
      permission_export: false,
      permission_user_management: false,
      permission_org_management: false,
      permission_settings: false,
      permission_see_pricing: false,
      permission_market_place: false,
      permission_alerts_place: false,
      permission_new_shipments: false,

      permission_shipper_panel: false,
      permission_carrier_panel: false,
      permission_driver_panel: true,

      preference_favorite_panel: 'driver',

      preference_show_shipments_to_pickup: true,
      preference_show_shipments_in_vehicle: true,
      preference_show_shipments_to_deliver: true,

      preference_show_market_place: false,
      preference_show_facturation: false,
      preference_show_activity: false,
    }
    this.openUserEditor(data)
  }

  onAddUser() {
    const data: IUser = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',

      permission_pipeline: true,
      permission_dispatch: true,
      permission_invoicing: true,
      permission_client_management: true,
      permission_empties_management: true,
      permission_warehouse_management: true,
      permission_activity: true,
      permission_export: true,
      permission_user_management: true,
      permission_org_management: true,
      permission_settings: true,
      permission_see_pricing: true,
      permission_market_place: true,
      permission_alerts_place: true,
      permission_new_shipments: true,

      permission_shipper_panel: true,
      permission_carrier_panel: true,
      permission_driver_panel: true,

      preference_favorite_panel: '',

      preference_show_shipments_to_pickup: false,
      preference_show_shipments_in_vehicle: false,
      preference_show_shipments_to_deliver: false,

      preference_show_market_place: true,
      preference_show_facturation: true,
      preference_show_activity: true,
    }
    this.openUserEditor(data)
  }

  onDetails(user: IDetailedUser) {
    this.router.navigate(['/main/carrier/settings/users/' + user.uuid + '/details'])
  }

  onMaps(user: IDetailedUser) {
    this.router.navigate(['/main/carrier/driver-locations/' + user.uuid])
  }

  onEditUser(user: IDetailedUser) {
    const data = deepCopy(user)
    this.dialog.open(UserEditorComponent, { data: data }).afterClosed().subscribe(async (val) => {
      if (!!val) {
        if (val.email == '') {
          val.email = null;
        }
        if (val.phone == '') {
          val.phone = null;
        }
        try {
          const successMessage = await lastValueFrom(this.translateService.get('toastr.user_updated'));

          await this.usersService.updateUser(user.uuid, val).toPromise();
          this.toastrService.success(successMessage)
          this.refresh();
        } catch (error) {
          this.toastrService.error(JSON.stringify(error))
        }
      }
    })
  }


  onEditPassword(user: IDetailedUser) {
    this.dialog.open(UserPasswordEditorComponent, { data: user }).afterClosed().subscribe(async val => {
      if (!!val) {
        await this.usersService.updateUserPassword(user, val).toPromise();
        const infoMessage = await lastValueFrom(this.translateService.get('toastr.set_new_user_password', { username: user.username, password: val.password }));
        const successMessage = await lastValueFrom(this.translateService.get('toastr.new_password_set'));
        this.toastrService.success(infoMessage, successMessage, { disableTimeOut: true });
        this.refresh();
      }
    })
  }

}
