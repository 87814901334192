import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { versions } from 'projects/versions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime } from 'rxjs/operators';
import { IPrice } from '../price.interface';
import { ToastrService } from 'ngx-toastr';
import { IClient } from 'projects/webapp/app/services/clients.service';
import { generateShipmentFormGroup, generatePacketFormGroup } from '../generators';
import { FormArray, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';


@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input() price: IPrice | null = null;;
  @Input() withClientSelection: boolean = true;;
  @Input() showPricingSelection: boolean = true;;

  modalRef?: BsModalRef;

  public shipmentForm = generateShipmentFormGroup()
  public packetItemForms: FormArray = this.shipmentForm.get('packets') as FormArray;

  isLoadingPricing = false;
  isLoadingProcessing = false;
  versions = versions;
  initialValue = {}

  currentShipmentKey = 'currentShipment'

  ownOrganization: IOrganization | null = null;

  @Output() onShipmentRequest: EventEmitter<any> = new EventEmitter()
  @Output() onShipmentPriceRequest: EventEmitter<any> = new EventEmitter()

  constructor(
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.shipmentForm.get('empties')?.valueChanges.subscribe(emptiesValue => {
      const dependentControls = ['empties_eur_palets', 'empties_sbb_frames', 'empties_sbb_covers'];

      dependentControls.forEach(controlName => {
        const dependentControl = this.shipmentForm.get(controlName);
        if (!!dependentControl){

          if (emptiesValue) {
            dependentControl.setValidators(Validators.required);
          } else {
            dependentControl.clearValidators();
          }

          dependentControl.updateValueAndValidity();
        }
      });
    });
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    const val = localStorage.getItem(this.currentShipmentKey);
    if (!!val) {
      console.log('had a current shipment in the session')
      this.shipmentForm.patchValue(JSON.parse(val))
      this.initialValue = this.shipmentForm.value
      this.fetchPricing()
    }

    this.shipmentForm.valueChanges.pipe(
      // tap(() => (this.isLoadingPricing = true)),
      debounceTime(300)
    ).subscribe(val => {

      console.log(val)
      localStorage.setItem(this.currentShipmentKey, JSON.stringify(val));
      this.fetchPricing();
      this.initialValue = this.shipmentForm.value
    });
  }

  async fetchPricing() {
    try {
      // const response = await lastValueFrom(this.wizardService.calculate_price(this.draftShipmentForm.value));
      this.onShipmentPriceRequest.emit(this.shipmentForm.value)
      // this.price = response;
      // this.isLoadingPricing = false;
    } catch (error) {
      console.error(error)
    }
  }

  resetForm() {
    this.shipmentForm.reset();
  }

  public addPacketItem(): void {
    const cargoItemsCount = this.packetItemForms.controls.length;
    const cargoItemForm = generatePacketFormGroup();
    this.packetItemForms.insert(cargoItemsCount, cargoItemForm);
  }

  public removePacketItem(index: number): void {
    this.packetItemForms.removeAt(index);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async sendRequest() {
    console.log('sendRequest')
    this.shipmentForm.markAllAsTouched();

    if (!this.shipmentForm.valid) {
      // this.packetItemForms.controls.forEach((control: AbstractControl) => {
      //   if (control instanceof FormGroup) {
      //     control.markAllAsTouched();
      //     // If the control is a FormGroup, iterate over its controls
      //     Object.keys(control.controls).forEach((key: string) => {
      //       const nestedControl = control.get(key);
      //       if (nestedControl && nestedControl.errors) {
      //         // Access the errors for the specific nested control
      //         console.log(`Errors for ${key}:`, nestedControl.errors);
      //       }
      //     });
      //   } else if (control.errors) {
      //     // Access the errors for the specific control in the FormArray
      //     console.log('Errors:', control.errors);
      //   }
      // });

      for (const controlName in this.shipmentForm.controls) {
        if (this.shipmentForm.controls.hasOwnProperty(controlName)) {
          console.log(`Errors for ${controlName}:`);
          const control = this.shipmentForm.controls[controlName as keyof typeof this.shipmentForm.controls];
          control.markAllAsTouched();

          if (control.errors) {
            // Access the errors for the specific form control
            console.log(`Errors for ${controlName}:`, control.errors);
          }
        }
      }
      this.translate.get('toastr.incomplete_input').subscribe((translatedMessage: string) => {
        this.toastrService.error(translatedMessage);
      });
      return
    }

    this.isLoadingProcessing = true;

    try {
      this.onShipmentRequest.emit(this.shipmentForm.value)
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingProcessing = false;
    }
  }

  onSelectedClient(event: IClient) {
    this.shipmentForm.patchValue({ client: event.uuid })
  }

  closeSuccess() {
    this.modalRef?.hide();
    this.shipmentForm.reset();
    localStorage.removeItem(this.currentShipmentKey)
  }
}
