<div class="container">

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: column; justify-content: space-between;">
                <h1>{{'common.deliver' | translate}}</h1>
                <h5>{{shipment?.number | shipmentNumberPipe}}</h5>

            </div>

            <ngx-spinner name="do-deliver" [fullScreen]="false"></ngx-spinner>


            <table *ngIf="shipment && !!shipment.packets && shipment.packets.length > 0" style="width: 100%"
                class="table table-custom">
                <thead>
                    <td>{{'common.name' | translate}}</td>
                    <td>{{'common.type' | translate}}</td>
                    <td>{{'common.quantity' | translate}}</td>
                    <td>{{'common.mass' | translate}} (cm)</td>
                    <td>{{'common.total_weight' | translate}} (kg)</td>
                </thead>

                <tbody>
                    <tr *ngFor="let packet of shipment.packets">
                        <td [attr.data-label]="'common.name' | translate">{{packet.name}}</td>
                        <td [attr.data-label]="'common.type' | translate">{{packet.type | cargoItemName}}</td>
                        <td [attr.data-label]="'common.quantity' | translate">{{packet.count}}</td>
                        <td [attr.data-label]="'common.mass' | translate">L{{packet.length}}xB{{packet.width}}xH{{packet.height}}</td>
                        <td [attr.data-label]="'common.total_weight' | translate">{{packet.weight}}</td>
                    </tr>
                </tbody>
            </table>

            <form [formGroup]="deliverInfoForm" (ngSubmit)="save()">
                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" id="deliver_signature_name"
                            formControlName="deliver_signature_name">
                        <label for="deliver_signature_name" class="form-label">{{'common.person_name' | translate}}</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" id="deliver_comment" formControlName="deliver_comment">
                        <label for="deliver_comment" class="form-label">{{'common.comment' | translate}}</label>
                    </div>
                </div>
            </form>

            <button class="btn-primary dark big w-100" (click)="onAddImage()">{{'common.add_delivery_image' | translate}} <mat-icon>image</mat-icon></button>

            <button class="btn-primary green big mt-3 w-100" (click)="save()">{{'common.complete_delivery' | translate}} <mat-icon>check_circle</mat-icon></button>
        </mat-card-content>
    </mat-card>
</div>