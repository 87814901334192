<div class="container-fluid mt-1">
    <div class="page-header-container container">
        <h2>{{'carrier_dashboard.marketplace.main_title' | translate}}</h2>

        <div class="buttons-container">
            <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
            <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </a>
        </div>

    </div>
    <div class="row">
        <div class="col-12">
            <div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
                <div class="alert alert-info">{{'carrier_dashboard.marketplace.no-shipment' | translate}} </div>
            </div>
        </div>

        <div class="col-12">
            <div class="text-center mt-2 mb-3">
                <!-- <div class="alert alert-info">Aktuell {{'common.no_shipments_yet' | translate}}</div> -->
                <a routerLink="/main/shipper/market-place" style="width: fit-content;"
                    class="btn-primary dark big mobile-text primary-hover-animation mx-auto">{{'carrier_dashboard.marketplace.own-shipment'
                    | translate}} </a>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4" *ngFor="let shipment of shipments">
            <div class="mb-2">
                <app-market-place-shipment-card [shipment]="shipment"
                    (onShipmentOpenDetails)="openShipmentDetails(shipment)"></app-market-place-shipment-card>
            </div>
        </div>
    </div>
</div>