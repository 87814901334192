<div class="container mt-3">

  <div class="page-header-container container">
    <h2>{{'common.enter_shipment' | translate}}</h2>
  </div>

  <div class="row mb-4" *ngIf="withClientSelection">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h5 class="card-title" translate>common.client</h5> -->
          <app-client-selector [selectedClientUUID]="shipmentForm.value.client" [isRequired]="true"
            (onSelectedClient)="onSelectedClient($event)"></app-client-selector>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-sm-6 mb-3 mb-sm-0">
      <app-location-form [shipmentForm]="shipmentForm" [type]="'pickup'"></app-location-form>
    </div>
    <div class="col-sm-6">
      <app-location-form [shipmentForm]="shipmentForm" [type]="'delivery'"></app-location-form>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ng-container *ngFor="let packetForm of packetItemForms.controls; index as index">
        <div class="mb-5">
          <app-shipment-packets [index]="index" [packetForm]="packetForm"
            [number_of_packets]="packetItemForms.controls.length"
            (deletePacket)="removePacketItem($event)"></app-shipment-packets>
        </div>
      </ng-container>
    </div>
    <div class="d-flex justify-content-center mb-4">
      <button class="btn-primary big green primary-hover-animation" (click)="addPacketItem()">
        {{'carrier_dashboard.add_another_cargo' | translate}}
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </div>

  <app-extras-form [shipmentForm]="shipmentForm"></app-extras-form>

  <div class="mb-5 d-flex gap-2 flex-md-row flex-column justify-content-between">
    <div>
      <div class="total-price-container d-flex gap-2 align-items-center" *ngIf="showPricingSelection">
        <mat-icon>double_arrow</mat-icon>
        {{'common.price' | translate}}:
        <span *ngIf="isLoadingPricing">
          <div class="spinner-border spinner-border-sm" role="status">
          </div>
        </span>
        <span *ngIf="!!price && !isLoadingPricing">
          {{ownOrganization?.default_currency}} {{ price.total_price | number: '1.2-2'}}
          <span class="glyphicon glyphicon-pencil"></span>
          <button type="button" class="info-btn" (click)="openModal(pricing)">
            <mat-icon>info</mat-icon>

          </button>
        </span>
        <span *ngIf="!price && !isLoadingPricing">
          -
        </span>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <button class="btn-primary dark primary-hover-animation me-2" (click)="resetForm()">
        {{'common.reset' | translate}}
      </button>

      <button class="btn-primary green primary-hover-animation" (click)="sendRequest()">
        {{'common.next' | translate}}
      </button>
    </div>

  </div>

  <!-- <pre>{{shipmentForm.value | json}}</pre> -->

</div>

<ng-template #pricing>
  <div class="modal-header">
    <h4 class="modal-title pull-left" translate>carrier_dashboard.price_overview</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <pre>{{price | json}}</pre> -->
    <table *ngIf="!!price && price" style="width: 100%" class="table table-striped">
      <thead>
        <td translate>common.position</td>
        <td translate>common.sum</td>
      </thead>

      <tbody>

        <tr>
          <td translate>common.transport_price</td>
          <td>{{ownOrganization?.default_currency}} {{price.base_price | number: '1.2-2'}}</td>
        </tr>

        <tr *ngFor="let option of price.options">
          <td>{{option.name}}</td>
          <td>{{ownOrganization?.default_currency}} {{option.price | number: '1.2-2'}}</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.excl_vat' |
            translate}})</td>
          <td style="font-weight: bold;">{{ownOrganization?.default_currency}} {{price.total_price | number: '1.2-2'}}</td>
        </tr>

        <tr>
          <td translate>common.value_added_tax</td>
          <td>{{ownOrganization?.default_currency}} {{price.total_vat | number: '1.2-2'}}</td>
        </tr>

        <tr>
          <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.incl_vat' |
            translate}})</td>
          <td style="font-weight: bold;">{{ownOrganization?.default_currency}} {{price.total_price_with_vat | number: '1.2-2'}}</td>
        </tr>

      </tbody>
    </table>
    <button class="btn-primary dark w-100" (click)="modalRef?.hide()">{{'common.close' | translate}}</button>
  </div>
</ng-template>