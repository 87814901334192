<div #mapElement class="map match-parent" id="map"></div>

<!-- <iframe width='100%' height='400px'
  src="https://api.mapbox.com/styles/v1/neptundesign/cm046bxjh00f401qo7s408u06.html?title=false&access_token=pk.eyJ1IjoibmVwdHVuZGVzaWduIiwiYSI6ImNtMDQ2M29kdDAzd2QyanMybHZpeng2amIifQ.gcRd_8r2V4_NebGJe1bZSw&zoomwheel=false#7.4/47.005/8.058"
  title="Untitled" style="border:none;"></iframe> -->

    <div class="driver-info top-info container" *ngIf="selectedDriver">
        <div class="row mb-2">
            <div class="col-2">
                <div class="avatar-container d-flex justify-content-center align-items-center">
                    <mat-icon>person</mat-icon>
                </div>
            </div>
            <div class="col-5 justify-center">
                <p class="font-weight-bold">{{ selectedDriver.first_name }} {{ selectedDriver.last_name }}</p>
                <p>*****</p>
            </div>
            <div class="col-5">
                <button class="btn-primary dark">Contact</button>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <p class="opacity-50">Driver-NR</p>
                <p class="font-weight-bold">DR-2456</p>
            </div>
            <div class="col-4">
                <p class="opacity-50">Plate-NR</p>
                <p class="font-weight-bold">AG456785</p>
            </div>
            <div class="col-4">
                <p class="opacity-50">Vehicle</p>
                <p class="font-weight-bold">Iveco U83</p>
            </div>
        </div>

        <div class="row">

        </div>
        
    </div>

    <!-- <div class="driver-info route-info container">
        <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" class="mt-1 mb-1">
            <mat-tab label="Route">

                <div class="d-flex justify-content-between gap-2 mt-2 mx-1">
                    <div class="standout col-3">
                        <p>Route</p>
                        <p>892km</p>
                    </div>
                    <div class="standout col-3">
                        <p>Time</p>
                        <p>8.3h</p>
                    </div>
                    <div class="col-4">
                        <button class="btn-primary dark">See route</button>
                    </div>
                </div>
                <div class="">

                </div>

            </mat-tab>
            <mat-tab label="Documents">
                <div class="previous-routes-container">
                    <mat-icon>history</mat-icon>
                    <p>Previous Routes</p>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div> -->