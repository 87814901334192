<div class="kachel position-relative overflow-hidden" *ngIf="shipment">
    <div style="min-height: 468px;" class="z-1 mx-2 mx-md-4 my-2 d-flex flex-column justify-content-between">
        <div
            class="row mb-4">
                <div class="col-6 d-flex flex-column">
                    <span class="w-light text-secondary">{{'common.consignor' | translate}}</span>
                    <a style="cursor: pointer;" class="fw-bold fs-3 cursor-pointer" *ngIf="shipment.orderer" (click)="openOrdererDetails()">
                            {{shipment.orderer.name}}
                    </a>
                    <span class="fw-bold" *ngIf="!shipment.orderer">-</span>
                </div>
   
                <div class="col-6 d-flex flex-column">
                    <span class="w-light text-secondary">{{'common.kind' | translate}}</span>
                    <span class="fw-bold">{{shipment.price_mode | priceModePipe}}</span>
                </div>
        </div>

        <div class="row mb-4">
            <div class="col-6 d-flex flex-column">
                <span class="fw-light text-secondary">{{'common.pickup' | translate}}</span>
                <span class="fw-bold">{{shipment.pickup_date}}</span>
                <span class="fw-bold"><!-- {{shipment.pickup_zip}} --> {{shipment.pickup_location}}</span>
            </div>
            <div class="col-6 d-flex flex-column">
                <span class="fw-light text-secondary">{{'common.delivery' | translate}}</span>
                <span class="fw-bold">{{shipment.delivery_date}}</span>
                <span class="fw-bold"><!-- {{shipment.delivery_zip}}  -->{{shipment.delivery_location}}</span>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-6 d-flex flex-column">
                <span class="fw-light text-secondary" translate>common.ground_spaces</span>
                <span class="fw-bold">{{ shipment.total_pallet_places | number:'1.2-2' }} {{'common.spaces' |
                    translate}}</span>
            </div>
            <div class="col-6 d-flex flex-column">
                <span class="fw-light text-secondary">{{'common.weight' | translate}}</span>
                <span class="fw-bold">{{shipment.total_weight}} kg</span>
            </div>
        </div>

        <div class="row">
            <div class="col-8">
                <div style="filter: invert(1);">
                    <h3 class="fs-4 fw-light mb-0">{{'common.price' | translate}}</h3>
                    <h3 class="fs-2 fw-bolder">{{ownOrganization?.default_currency}} {{shipment.market_price | number:
                        '1.2-2'}}</h3>
                </div>
            </div>

            <div class="col-4 d-flex align-items-center">

                <button [matTooltip]="'common.more_information' | translate" [routerLink]="[]"
                    (click)="openShipmentDetails()" class="btn-primary blue big-icon primary-hover-animation ms-auto">
                    <mat-icon>info</mat-icon>
                    <!-- <span>{{'common.more_information' | translate}}</span> -->
                </button>
            </div>

        </div>
    </div>
    <img class="position-absolute z-0 top-0 end-0 opacity-50 m-4" src="assets/cargo_simple.svg" alt="cargoflow logo">
    <img style="width: 120%;" class="position-absolute z-0 bottom-0" src="assets/waves.svg" alt="waves">
</div>