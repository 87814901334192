import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import * as mapboxgl from 'mapbox-gl';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  isLoading = true;
  map!: mapboxgl.Map;
  lat = 47.4389;
  lng = 9.3669;

  @Input() pins: any[] = [];
  @ViewChild('mapElement', { static: true }) mapElement!: ElementRef;

  accessToken: string = environment.mapboxToken;
  markers: mapboxgl.Marker[] = [];
  selectedDriver: any = null;

  constructor(
    private organizationService: OrganizationsService,
    private usersService: UsersService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.fetchUserLocation();
  }

  fetchUserLocation(): void {
    this.isLoading = true;
    this.http.get<any>('http://ip-api.com/json/')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.initializeMap(response.lat, response.lon);
        },
        error: () => {
          console.error('Failed to fetch user location');
          this.initializeMap(46.776, 7.56);
        }
      });
  }

  initializeMap(lat: number, lng: number): void {
    (mapboxgl as any).accessToken = this.accessToken;
    this.map = new mapboxgl.Map({
      container: this.mapElement.nativeElement,
      style: 'mapbox://styles/neptundesign/cm046bxjh00f401qo7s408u06',
      center: [lng, lat],
      zoom: 6.326,
      minZoom: 6
    });

    this.map.on('load', () => {
      this.addMarkers();
      this.addMapControls();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pins'] && this.map) {
      this.updateMarkers();
    }
  }

  addMarkers(): void {
    this.pins.forEach(markerData => this.createMarker(markerData));
  }

  createMarker(markerData: any): void {
    const el = document.createElement('div');
    el.className = 'custom-marker';

    switch (markerData.title) {
      case 'Driver Location':
        el.classList.add('driver-marker');
        break;
      case 'Pickup Location':
        el.classList.add('pickup-marker');
        break;
      case 'Delivery Location':
        el.classList.add('delivery-marker');
        break;
      default:
        el.classList.add('default-marker');
    }

    // el.style.width = '10px';
    // el.style.height = '10px';
    // el.style.borderRadius = '50%';
    // el.style.backgroundSize = 'cover';
    // el.style.backgroundColor = 'blue';

    // you should be able to add the "el" as a argument bellow
    const marker = new mapboxgl.Marker({ element: el })
    .setLngLat([markerData.lng, markerData.lat])
    .addTo(this.map);

    marker.getElement().addEventListener('click', (event) => {
      event.stopPropagation(); // Prevent map click event from being triggered
      if (markerData.title === 'Driver Location') {
        this.selectedDriver = markerData; // Set the selected driver data
      }
    });

    this.markers.push(marker);
  }

  updateMarkers(): void {
    this.clearMarkers();
    this.addMarkers();
    // this.pins.forEach(markerData => this.createMarker(markerData));
  }

  clearMarkers(): void {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }


  // CONTROLS
  addMapControls(): void {
    this.map.scrollZoom.enable();
    this.map.addControl(new mapboxgl.NavigationControl());
    this.addMouseControls();
  }

  addMouseControls(): void {
    this.map.on('mouseenter', () => this.map.getCanvas().style.cursor = 'pointer');
    this.map.on('mouseleave', () => this.map.getCanvas().style.cursor = '');
    this.map.on('click', (event) => this.handleMapClick(event));
    this.map.on('contextmenu', (event) => this.handleMapRightClick(event));
  }

  handleMapClick(event: mapboxgl.MapMouseEvent): void {
    const { lng, lat } = event.lngLat;
    console.log(`Map clicked at longitude: ${lng}, latitude: ${lat}`);

    this.selectedDriver = null;
  }

  handleMapRightClick(event: mapboxgl.MapMouseEvent): void {
    const { lng, lat } = event.lngLat;
    console.log(`Map right-clicked at longitude: ${lng}, latitude: ${lat}`);
  }

  ngAfterViewInit(): void {
    // All map initialization is handled in fetchUserLocation -> initializeMap
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
