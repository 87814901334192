<div class="container-fluid">

  <div class="page-header-container">
    <h2 class="order-1">{{'carrier_dashboard.dispatch_pipeline.main_title' | translate}}</h2>

    <div class="buttons-container order-2">

      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>

      <span class="fs-4 mr-3">
        <span *ngIf="shipmentsForDispatch.length > 1">{{'common.open_shipments' | translate}}</span>
        <span *ngIf="shipmentsForDispatch.length == 1">{{'common.open_shipment' | translate}}</span>
        <span *ngIf="shipmentsForDispatch.length == 0">{{'common.no_shipment' | translate}}</span>
        : {{shipmentsForDispatch.length}}</span>
        <div class="d-flex gap-2">

          <button [matTooltip]="'common.assign_to_vehicle' | translate" class="btn-primary green big-icon mobile-text primary-hover-animation"
            (click)="dispatchToTour()" [disabled]="selectedShipments.length == 0">
            <mat-icon>drive_eta</mat-icon>
            <span>{{'common.assign_to_vehicle' | translate}}</span>
          </button>
    
          <button [matTooltip]="'common.manage_tours' | translate" class="btn-primary green big-icon mobile-text primary-hover-animation"
            routerLink="/main/carrier/tours">
            <mat-icon>map</mat-icon>
            <span>{{'common.manage_tours' | translate}}</span>
          </button>
    
          <a  mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </a>
        </div>

    </div>

  </div>

  <div class="text-center mt-5" *ngIf="!isLoading && shipmentsForDispatch.length == 0">
    <div class="alert alert-info">{{'carrier_dashboard.pipeline_dispatch.no_shipments_to_dispatch' | translate}}
    </div>
  </div>

  <!-- <pre>{{shipmentsForDispatch.length}}</pre>
      <pre>{{selectedShipments.length}}</pre>
      <pre>{{filteredShipments.length}}</pre> -->

  <div *ngIf="!!shipmentsForDispatch && shipmentsForDispatch.length > 0" class="main-container">
    <!-- <app-shipments-filters (onFilterChanges)="applyFilters($event)"></app-shipments-filters> -->

    <!-- <hr> -->
    <app-filtered-tags (onChangeSelectionEvent)="onChangeSelectionEvent($event)"
      (onUpdateFilterEvent)="onChangeFilterEvent($event)" (onChangeSortingEvent)="onChangeSorting($event)"
      [filterProperties]="filterProperties"></app-filtered-tags>
    <!-- <hr> -->

    <div class="text-center mt-5" *ngIf="!isLoading && filteredShipments.length == 0">
      <div class="alert alert-info">{{'common.no_shipments_with_filter' | translate}}</div>
    </div>

    <div class="scrollable-list" *ngIf="!isLoading && filteredShipments.length >= 0">
      <table class="table table-custom" style="width: 100%;">
        <thead>
          <tr>
            <th>
              <!-- <mat-icon class="p-0">check_circle_outline</mat-icon> -->
            </th>
            <th>{{'common.number' | translate}}</th>
            <th>{{'common.assigned_to' | translate}}</th>
            <th>{{'common.client' | translate}}</th>
            <th>{{'common.from' | translate}}</th>
            <th>{{'common.to' | translate}}</th>
            <th>{{'common.weight' | translate}}</th>
            <th></th>
          </tr>
          <tr class="top-fade">
            <th colspan="8"></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let shipment of filteredShipments">
            <td [attr.data-label]="'common.select' | translate">
              <!-- <input type="checkbox" (toggle)="onToggleSelection($event, shipment)"> -->
              <mat-checkbox (change)="onToggleSelection($event, shipment)"
                [checked]="isShipmentChecked(shipment)"></mat-checkbox>
            </td>
            <td [attr.data-label]="'common.number' | translate">
              <span>
                {{shipment.number | shipmentNumberPipe }}
              </span>
              <br>
              <span style="padding: 3px; border-radius: 3px; color: white;"
                *ngIf="shipment.status === shipmentStatus.IN_HUB">{{'common.in_hub' | translate}}</span>
            </td>
            <td [attr.data-label]="'common.assigned_to' | translate">
              <a class="green-btn primary-hover-animation d-flex align-items-center gap-2 justify-content-center ms-auto ms-sm-0"
                style="width: fit-content;" *ngIf="shipment.assigned_tour_object"
                routerLink="/main/carrier/tours/{{shipment.assigned_tour_object.uuid}}">{{shipment.assigned_tour_object.name}}</a>
              <span class="opacity-50" *ngIf="!shipment.assigned_tour_object">{{'common.no_vehicle_assigned' |
                translate}}</span>
            </td>
            <td [attr.data-label]="'common.client' | translate">
              <ul class="co-simple-list" *ngIf="shipment.client_object">
                <li>
                  <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">{{shipment.client_object.company
                    }}</a>
                </li>
                <li>
                  <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                    {{shipment.client_object.first_name }}
                    {{shipment.client_object.last_name }}
                  </a>
                </li>
              </ul>
              <span class="opacity-50" *ngIf="!shipment.client_object">{{'common.no_client_assingned' |
                translate}}</span>
            </td>
            <td [attr.data-label]="'common.from' | translate">
              <!-- <span *ngIf="shipment.pickup_company">
                        {{shipment.pickup_company }}
                        <br>
                      </span>
                      <span *ngIf="shipment.pickup_name">
                        {{shipment.pickup_name }}
                        <br>
                      </span>
                      {{shipment.pickup_address }}
                      <br> -->
              <!-- {{shipment.pickup_zip }} --> {{shipment.pickup_location }}
              <br>
              {{shipment.pickup_date }}
            </td>

            <td [attr.data-label]="'common.to' | translate">
              <!-- <span *ngIf="shipment.delivery_company">
                        {{shipment.delivery_company }}
                        <br>
                      </span>
                      <span *ngIf="shipment.delivery_name">
                        {{shipment.delivery_name }}
                        <br>
                      </span>
                      {{shipment.delivery_address }}
                      <br> -->
              <!-- {{shipment.delivery_zip }} --> {{shipment.delivery_location }}
              <br>
              {{shipment.delivery_date }}
            </td>
            <td [attr.data-label]="'common.weight' | translate">
              {{shipment.total_weight | number: '1.2-2' }} Kg
            </td>

            <!-- <td>
                      <a *ngIf="shipment.assigned_driver_object"
                        routerLink="/main/carrier/settings/users/{{shipment.assigned_driver_object.uuid}}/details">
                        {{shipment.assigned_driver_object.first_name}} {{shipment.assigned_driver_object.last_name}}
                      </a>
        
                      <div class="float-end">
                        <a mat-icon-button (click)="dispatchShipment(shipment)">
                          <mat-icon *ngIf="!shipment.assigned_driver_object">person_add</mat-icon>
                          <mat-icon *ngIf="shipment.assigned_driver_object">edit</mat-icon>
                        </a>
                      </div>
                    </td> -->
            <td class="float-right">
              <!-- TODO: translate -->
              <button matTooltip="More information"
                class="btn-primary blue big-icon mobile-text primary-hover-animation d-flex align-items-center justify-content-center ms-auto"
                routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                <mat-icon>info</mat-icon>
                <span>Info</span>
              </button>

            </td>
          </tr>
        </tbody>
      </table>
      <div class="bottom-container-fade">
        <div class="fade-container"></div>
      </div>
    </div>

    <!-- <hr> -->

    <div class="static-content mt-1 mb-2">
      <h1>{{'common.my_trucks' | translate}}</h1>
      <div class="card-container" *ngIf="tours">

        <mat-card *ngFor="let tour of tours" class="mb-2 me-2 position-relative">
          <mat-card-header class="w-100">
            <mat-card-subtitle>
              <app-vehicle-card [tour]="tour"></app-vehicle-card>
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-actions>
            <button color="primary" mat-icon-button (click)="onShowTourDetails(tour)">
              <mat-icon>room</mat-icon>
            </button>
            <a color="primary" mat-icon-button routerLink="/main/carrier/tours/{{tour.uuid}}" *ngIf="tour && tour.uuid">
              <mat-icon>edit</mat-icon>
            </a>
          </mat-card-actions>
        </mat-card>

        <!-- <pre>{{selectedShipments | json}}</pre> -->

      </div>

      <div *ngIf="tours.length == 0">
        <div class="alert alert-info w-100">
          {{'common.no_tours_to_dispatch_yet' | translate}} <a
            routerLink="/main/carrier/tours">{{'common.tour_management' | translate}}</a>
          {{'common.create_new' | translate}}
        </div>

        <button mat-flat-button color="primary" (click)="createToursForVehicles()">
          <mat-icon>drive_eta</mat-icon>
          Create Tours for all your vehicles
        </button>
      </div>

    </div>

  </div>

</div>