import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ICarrierShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { CurrentLocationStatus, IShipment, ShipmentTaskStatus } from 'projects/webapp/app/services/shipments.service';
import { openInGoogleMaps } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipments-assigned',
  templateUrl: './shipments-assigned.component.html',
  styleUrls: ['./shipments-assigned.component.scss']
})
export class ShipmentAssignedComponent implements OnInit {

  currentLocationStatus = CurrentLocationStatus
  shipmentTaskStatus = ShipmentTaskStatus

  shipments: ICarrierShipment[] = [];
  isLoading = false;

  type: 'pickup' | 'delivery' | 'inVehicle' = 'pickup'
  total_weight: number = 0;
  total_ground_spaces: number = 0;
  total_ground_space_m2: number = 0;

  showItems = false;

  constructor(
    private carrierShipmentsService: CarrierShipmentsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(val => {
      if (!!val && val['type']) {
        this.type = val['type']
        this.refresh()
      }
    })
  }

  async refresh() {
    try {
      this.spinnerService.show('shipments-assigned')
      this.isLoading = true;
      this.shipments = await lastValueFrom(this.carrierShipmentsService.getShipmentsAssigned({
        type: this.type,
        userUUID: this.authService.getUserUUID()
      }))
      console.log(this.shipments, 'shipments')
      this.calculateTotals();
      this.isLoading = false;
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('shipments-assigned')
    }
  }

  calculateTotals() {
    this.total_weight = 0
    this.total_ground_space_m2 = 0;
    this.total_ground_spaces = 0;
    this.shipments.forEach(el => {
      this.total_weight += el.total_weight
      el.packets.forEach(packet => {
        this.total_ground_space_m2 += packet.length / 100 * packet.width / 100
        this.total_ground_spaces += packet.length / 100 * packet.width / 100 / 0.96
      })
    })
  }

  close(shipment: IShipment) {
    if (this.type === 'pickup') {
      this.router.navigate([`/main/driver/shipments/${shipment.uuid}/do-pickup`])
      return;
    }
    if (this.type === 'delivery') {
      this.router.navigate([`/main/driver/shipments/${shipment.uuid}/do-deliver`])
    }
  }

  openInGoogleMaps(arg0: string, arg1: string, arg2: string) {
    openInGoogleMaps(arg0, arg1, arg2)
  }

  async setAtHub(shipment: IShipment) {
    if (shipment.uuid) {
      try {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_to_hub'));
        this.spinnerService.show('do-pickup')
        await lastValueFrom(this.carrierShipmentsService.setAtHubShipment(shipment.uuid, {}))
        this.toastrService.success(successMessage)
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide('do-pickup')
      }
    }
    this.router.navigate(['/main/carrier/dashboard'])
  }

  async closeShipments() {
    if (this.type == 'pickup') {
      for (let index = 0; index < this.shipments.length; index++) {
        const element = this.shipments[index];
        if (element.uuid) {
          await lastValueFrom(this.carrierShipmentsService.pickupShipment(element.uuid, {
            pickup_signature_name: '',
            pickup_comment: 'collective pickup'
          }))
        }
      }
      const successMessage = await lastValueFrom(this.translateService.get('toastr.shipments_pickedup'));
      this.toastrService.success(successMessage);
      this.refresh()
    }

    if (this.type == 'delivery') {
      for (let index = 0; index < this.shipments.length; index++) {
        const element = this.shipments[index];
        if (element.uuid) {
          await lastValueFrom(this.carrierShipmentsService.deliverShipment(element.uuid, {
            deliver_signature_name: '',
            deliver_comment: 'collective delivery'
          }))
        }
      }
      const successMessage = await lastValueFrom(this.translateService.get('toastr.shipments_delivered'));
      this.toastrService.success(successMessage);
      this.refresh()
    }
  }
  toggleItems() {
    this.showItems = !this.showItems;
  }
}
