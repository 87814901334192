<ngx-spinner type="ball-scale-multiple">
  <button class="mt-3 btn-primary warning primary-hover-animation" (click)="cancelLoading()">
    {{'common.cancel' | translate}}
  </button>
</ngx-spinner>

<div class="main-container">
  <app-header (menuToggled)="toggle($event)"></app-header>

  <mat-drawer-container>
    <mat-drawer mode="side" #leftSideNav [opened]="opened">
      <div class="d-flex flex-column justify-content-between h-100">
        <app-menu-item [menu]="menu" (elementClicked)="menuItemClicked($event)"></app-menu-item>
        <div class="d-flex flex-column align-items-center" [ngClass]="panelType == 'driver'? 'padding-bottom': ''">
          <small class="align-self-center pb-3 text-muted">{{(organizationsService.ownOrg$ | async)?.name}}</small>
          <small class="align-self-center pb-3 text-muted">v{{versions.date}}</small>
        </div>
      </div>
    </mat-drawer>


    <mat-drawer-content [class.margin-left]="opened">
      <router-outlet></router-outlet>
      <!-- <pre>{{myUser | json}}</pre> -->
      <!-- <br>
            <br>
            <br>
            <br>
            <br>
            <br> -->
    </mat-drawer-content>
  </mat-drawer-container>


  <!-- only on mobile driver nav -->
  <mat-toolbar class="toolbarNav" *ngIf="panelType == 'driver'">
    <div class="toolbarNav__container">
      <button mat-flat-button [routerLink]="this.menu[1].link" routerLinkActive="active-link">
        <!-- <mat-icon class="material-icons color_blue">skip_previous</mat-icon> -->
        <!-- <img src="assets/icon/pickup.svg" style="height: 20px;" loading="lazy" alt=""> -->

        <svg height="28" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.40905 0C7.05915 0.0073125 6.80764 0.0760781 6.64107 0.197953L4.36393 1.42013L3.09582 3.14311C2.82927 3.55458 3.01906 4.35192 3.8533 3.84091L4.76502 2.63737C5.92461 1.63634 8.29199 2.09756 7.16938 4.10435C6.61789 5.26643 6.87742 5.8338 7.58563 6.07193L7.90935 4.98487C8.47067 3.72335 9.51486 3.49636 9.47225 2.47929L11.9872 2.66034L11.9669 0.034289L7.40905 0ZM6.14131 2.42198C5.73327 2.41495 5.33307 2.58366 5.04163 2.82921L4.12653 4.03226C4.37762 4.23162 4.6284 4.12505 4.87946 3.88312C5.17539 4.03298 5.40364 3.80058 5.59114 3.3371C5.66888 3.02095 5.78354 2.79717 6.14131 2.42198Z"
            fill="white" />
          <g clip-path="url(#clip0_34_59)">
            <path
              d="M6.99 6.06719L4.24 4.56251C4.16652 4.52191 4.08395 4.50061 4 4.50061C3.91605 4.50061 3.83348 4.52191 3.76 4.56251L1.01 6.06782C0.931465 6.11079 0.865907 6.17406 0.820172 6.25101C0.774438 6.32797 0.750203 6.4158 0.75 6.50532V9.49407C0.750203 9.58359 0.774438 9.67142 0.820172 9.74837C0.865907 9.82533 0.931465 9.8886 1.01 9.93157L3.76 11.4369C3.83348 11.4775 3.91605 11.4988 4 11.4988C4.08395 11.4988 4.16652 11.4775 4.24 11.4369L6.99 9.93157C7.06853 9.8886 7.13409 9.82533 7.17983 9.74837C7.22556 9.67142 7.2498 9.58359 7.25 9.49407V6.50563C7.24996 6.41595 7.22581 6.32793 7.18007 6.25079C7.13432 6.17365 7.06867 6.11024 6.99 6.06719ZM4 5.00001L6.51094 6.37501L5.58031 6.88407L3.06938 5.50907L4 5.00001ZM4 7.75001L1.48906 6.37501L2.54875 5.79469L5.05969 7.16969L4 7.75001ZM6.75 9.49532L4.25 10.8638V8.18219L5.25 7.63501V8.75001C5.25 8.81631 5.27634 8.8799 5.32322 8.92678C5.37011 8.97367 5.4337 9.00001 5.5 9.00001C5.5663 9.00001 5.62989 8.97367 5.67678 8.92678C5.72366 8.8799 5.75 8.81631 5.75 8.75001V7.36126L6.75 6.81407V9.49407V9.49532Z"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_34_59">
              <rect width="8" height="8" fill="white" transform="translate(0 4)" />
            </clipPath>
          </defs>
        </svg>

        <span>{{this.menu[1].title}}</span>
      </button>
      <button mat-flat-button [routerLink]="this.menu[2].link" routerLinkActive="active-link">
        <svg height="28" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.485 7.10079L6.36 4.84376C6.24978 4.78286 6.12592 4.75092 6 4.75092C5.87408 4.75092 5.75022 4.78286 5.64 4.84376L1.515 7.10173C1.3972 7.16618 1.29886 7.26108 1.23026 7.37652C1.16166 7.49196 1.1253 7.62369 1.125 7.75798V12.2411C1.1253 12.3754 1.16166 12.5071 1.23026 12.6226C1.29886 12.738 1.3972 12.8329 1.515 12.8974L5.64 15.1553C5.75022 15.2162 5.87408 15.2482 6 15.2482C6.12592 15.2482 6.24978 15.2162 6.36 15.1553L10.485 12.8974C10.6028 12.8329 10.7011 12.738 10.7697 12.6226C10.8383 12.5071 10.8747 12.3754 10.875 12.2411V7.75845C10.8749 7.62392 10.8387 7.49189 10.7701 7.37619C10.7015 7.26048 10.603 7.16536 10.485 7.10079ZM6 5.50001L9.76641 7.56251L8.37047 8.3261L4.60406 6.2636L6 5.50001ZM6 9.62501L2.23359 7.56251L3.82312 6.69204L7.58953 8.75454L6 9.62501ZM10.125 12.243L6.375 14.2956V10.2733L7.875 9.45251V11.125C7.875 11.2245 7.91451 11.3198 7.98483 11.3902C8.05516 11.4605 8.15054 11.5 8.25 11.5C8.34946 11.5 8.44484 11.4605 8.51517 11.3902C8.58549 11.3198 8.625 11.2245 8.625 11.125V9.04188L10.125 8.2211V12.2411V12.243Z" fill="white"/>
          <path d="M1.99417 2.26406C3.09992 1.53862 4.44682 1.27769 5.74348 1.53774L5.77133 1.54325C7.07859 1.80603 8.22811 2.57693 8.9674 3.68663L9.84388 4.99679L7.81397 4.59477C7.7176 4.57569 7.6176 4.59567 7.53596 4.65031C7.45433 4.70496 7.39774 4.7898 7.37866 4.88616C7.35957 4.98253 7.37955 5.08253 7.4342 5.16417C7.48884 5.2458 7.57368 5.30239 7.67004 5.32147L10.5768 5.89716C10.6732 5.91624 10.7732 5.89626 10.8548 5.84162C10.9365 5.78697 10.9931 5.70213 11.0121 5.60577L11.5878 2.69898C11.6069 2.60261 11.5869 2.50261 11.5323 2.42097C11.4776 2.33934 11.3928 2.28275 11.2964 2.26367C11.2001 2.24458 11.1001 2.26456 11.0184 2.3192C10.9368 2.37385 10.8802 2.45869 10.8611 2.55505L10.4591 4.58496L9.58555 3.27601C8.73766 2.0027 7.41876 1.1183 5.91889 0.817277L5.8874 0.81104C4.40101 0.512761 2.85694 0.812004 1.5896 1.64397C1.54711 1.66971 1.51024 1.70375 1.48117 1.74403C1.45211 1.78432 1.43145 1.83005 1.42041 1.87849C1.40938 1.92693 1.40821 1.97709 1.41696 2.02599C1.42571 2.07489 1.44421 2.12154 1.47135 2.16314C1.4985 2.20475 1.53374 2.24047 1.57497 2.26818C1.6162 2.29589 1.66259 2.31502 1.71137 2.32444C1.76015 2.33385 1.81032 2.33336 1.85891 2.32299C1.90749 2.31261 1.95349 2.29258 1.99417 2.26406Z" fill="white"/>
          </svg>
          
        <span>{{this.menu[2].title}}</span>
      </button>
      <!-- implement middle functionality -->
      <button mat-flat-button [routerLink]="this.menu[0].link" routerLinkActive="active-link" class="middle-element">
        <div>
          <!-- <img src="assets/driver/scan.svg" style="height: 20px;" loading="lazy" alt=""> -->
          <mat-icon style="color: white;"> dashboard </mat-icon>
        </div>
      </button>
      <button mat-flat-button [routerLink]="this.menu[3].link" routerLinkActive="active-link">
        <svg height="24" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.75 9.5L2 8H7.25L6.65 6.5H1.75L1 5H8.8L8.2 3.5H0.86L0 2H3.75C3.75 1.46957 3.96071 0.960859 4.33579 0.585786C4.71086 0.210714 5.21957 0 5.75 0H17.75V4H20.75L23.75 8V13H21.75C21.75 13.7956 21.4339 14.5587 20.8713 15.1213C20.3087 15.6839 19.5456 16 18.75 16C17.9544 16 17.1913 15.6839 16.6287 15.1213C16.0661 14.5587 15.75 13.7956 15.75 13H11.75C11.75 13.7956 11.4339 14.5587 10.8713 15.1213C10.3087 15.6839 9.54565 16 8.75 16C7.95435 16 7.19129 15.6839 6.62868 15.1213C6.06607 14.5587 5.75 13.7956 5.75 13H3.75V9.5H2.75ZM18.75 14.5C19.1478 14.5 19.5294 14.342 19.8107 14.0607C20.092 13.7794 20.25 13.3978 20.25 13C20.25 12.6022 20.092 12.2206 19.8107 11.9393C19.5294 11.658 19.1478 11.5 18.75 11.5C18.3522 11.5 17.9706 11.658 17.6893 11.9393C17.408 12.2206 17.25 12.6022 17.25 13C17.25 13.3978 17.408 13.7794 17.6893 14.0607C17.9706 14.342 18.3522 14.5 18.75 14.5ZM20.25 5.5H17.75V8H22.21L20.25 5.5ZM8.75 14.5C9.14782 14.5 9.52936 14.342 9.81066 14.0607C10.092 13.7794 10.25 13.3978 10.25 13C10.25 12.6022 10.092 12.2206 9.81066 11.9393C9.52936 11.658 9.14782 11.5 8.75 11.5C8.35218 11.5 7.97064 11.658 7.68934 11.9393C7.40804 12.2206 7.25 12.6022 7.25 13C7.25 13.3978 7.40804 13.7794 7.68934 14.0607C7.97064 14.342 8.35218 14.5 8.75 14.5Z" fill="white"/>
          </svg>
        <span>{{this.menu[3].title}}</span>
      </button>
      <!-- implement map button -->
      <button mat-flat-button [routerLink]="this.menu[4].link" routerLinkActive="active-link">
        <img src="assets/driver/profile.svg" style="height: 20px;" loading="lazy" alt="">
        <span>{{this.menu[4].title}}</span>
      </button>
    </div>
    <hr>
  </mat-toolbar>

</div>