import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public updateMyLocation(data: { lat: any, lng: any }): Observable<any> {
    return this.http.post<any>(this._urls.LOCATION, data)
  }

  public getRecentDriverLocations(): Observable<any> {
    return this.http.get<any>(this._urls.MAPS_DRIVERS_LOCATION)
  }

  public getDriverLocations(driverUUID: string, from_date: string, to_date: string): Observable<any> {
    return this.http.get<any>(this._urls.MAPS_DRIVER_BY_UUID_LOCATIONS(driverUUID), {
      params: {
        from_date, to_date
      }
    })
  }
}
