<div class="container-fluid position-relative">
  <mat-card>
    <mat-card-content>
      <div class="page-header-container container d-flex flex-row">
        <div class="w-100">
          <h2 *ngIf="type==='pickup'">{{'carrier_dashboard.shipments_to_pickup' | translate}}</h2>
          <h2 *ngIf="type==='delivery'">{{'carrier_dashboard.shipments_to_delivery' | translate}}</h2>
          <h2 *ngIf="type==='inVehicle'">{{'carrier_dashboard.shipments_in_vehicle' | translate}}</h2>
        </div>

        <div class="buttons-container">
          <a mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </a>
        </div>
      </div>

      <div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
        <div class="alert alert-info">{{'common.no_shipment' | translate}} </div>
      </div>
      <hr class="opacity-10" style="color: lightgrey;">

      <ngx-spinner name="shipments-assigned" [fullScreen]="false"></ngx-spinner>

      <!-- <div class="row" *ngIf="!isLoading && shipments.length != 0">
        <div class="col-4">{{'common.ground_spaces' | translate}}: {{total_ground_spaces | number: '1.2-2'}} </div>
        <div class="col-4">{{'common.area' | translate}} (m2): {{total_ground_space_m2 | number: '1.2-2'}}</div>
        <div class="col-4">{{'common.weight' | translate}} (kg): {{total_weight | number: '1.2-2'}}</div>
      </div> -->

      <table class="table table-custom d-none d-md-table" *ngIf="!isLoading && shipments.length != 0">
        <thead>
          <tr>
            <th>{{'common.number' | translate}}</th>
            <th>{{'common.client' | translate}}</th>
            <th>{{'common.from' | translate}} </th>
            <th>{{'common.to' | translate}} </th>
            <th>{{'common.weight' | translate}} (kg)</th>
            <th>

              <a class="btn-primary green w-100 text-decoration-none" *ngIf="['pickup', 'delivery'].includes(type)"
                [routerLink]="[]" (click)="closeShipments()">
                <span *ngIf="type=='pickup'">{{'carrier_dashboard.collective_pickup' | translate}}</span>
                <span *ngIf="type=='delivery'">{{'carrier_dashboard.collective_delivery' | translate}}</span>
              </a>

            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let shipment of shipments">
            <td name="Nummer">
              <a routerLink="/main/driver/shipments/{{shipment.uuid}}">
                {{shipment.number | shipmentNumberPipe }}
              </a>
            </td>
            <td name="Kunde">
              <span class="no-info" *ngIf="!shipment.client_object?.company">{{'common.no_info' | translate}}</span>
              <ul class="co-simple-list" *ngIf="shipment.client_object">
                <li>
                  <!-- <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">{{shipment.client_object.company
                                        }}</a> -->
                  <a [routerLink]="[]">{{shipment.client_object.company}}</a>
                </li>
                <li>
                  <!-- <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details"> -->
                  <a [routerLink]="[]">
                    {{shipment.client_object.first_name }}
                    {{shipment.client_object.last_name }}
                  </a>
                </li>
              </ul>
            </td>
            <td name="Abholadress">
              <!-- TODO make sure that this works aka open google maps -->
              <a [routerLink]="[]"
                (click)="openInGoogleMaps(shipment.pickup_address, shipment.pickup_zip, shipment.pickup_location)">
                {{shipment.pickup_address }}
                <br>
                {{shipment.pickup_zip }} {{shipment.pickup_location }}
                <br>
                {{shipment.pickup_date }}
              </a>
            </td>

            <td name="Zustelladresse">
              <!-- TODO make sure that this works aka open google maps -->
              <a [routerLink]="[]"
                (click)="openInGoogleMaps(shipment.delivery_address, shipment.delivery_zip, shipment.delivery_location)">
                {{shipment.delivery_address }}
                <br>
                {{shipment.delivery_zip }} {{shipment.delivery_location }}
                <br>
                {{shipment.delivery_date }}
              </a>
            </td>
            <td name="Gesamtgewicht">
              {{shipment.total_weight }}
            </td>

            <td>
              <div class="float-center">

                <a class="btn-primary green w-100 text-decoration-none" *ngIf="['pickup', 'delivery'].includes(type)"
                  [routerLink]="[]" (click)="close(shipment)">
                  <span *ngIf="type=='pickup'">{{'driver_app.dashboard.pick_up' | translate}}</span>
                  <span *ngIf="type=='delivery'">{{'common.delivered' | translate}}</span>
                </a>

                <div class="mt-3"
                  *ngIf="(shipment.current_loc == currentLocationStatus.AT_DELIVERY_TRUCK || shipment.current_loc == currentLocationStatus.AT_PICKUP_TRUCK) && (shipment.task == shipmentTaskStatus.PICKUP_VIA_HUB || shipment.task == shipmentTaskStatus.DELIVERY_VIA_HUB)">
                  <button class="btn-primary green w-100 text-decoration-none" (click)="setAtHub(shipment)">
                    {{'common.in_hub' | translate}} "{{shipment.assigned_hub_object?.name}}" {{'common.set' |
                    translate}}</button>
                </div>

              </div>
            </td>

          </tr>
        </tbody>
      </table>

      <!-- MOBILE -->
      <div class="mobile d-md-none" style="margin-bottom: 3rem;" *ngIf="!isLoading && shipments.length != 0">
        <div class="my-5" *ngFor="let shipment of shipments">

          <!-- <div style="font-size: 14px;" class="d-flex justify-content-between w-75 ps-4">
            <span>Stop 1</span>
            <span>103km</span>
            <span>1h 12min</span>
          </div> -->
          <div class="shipment-container position-relative">
            <div class="arrow-icon">
              <img src="assets/icon/truck.svg">
            </div>
            <div class="d-flex gap-2">
              <span class="fw-bold">{{'common.number' | translate}}: </span>
              <a routerLink="/main/driver/shipments/{{shipment.uuid}}">
                {{shipment.number | shipmentNumberPipe }}
              </a>
            </div>
            <hr class="opacity-10" style="color: lightgrey;">

            <!-- PICKUP -->
            <div *ngIf="type==='pickup'" class="">
              <div class="address-container d-flex flex-column justify-content-between mb-2">

                <div class="d-flex justify-content-between">
                  <span>{{'common.firma' | translate}}...</span>
                  <span><strong>{{shipment.pickup_company}}</strong> <span class="no-info"
                      *ngIf="!shipment.pickup_company">{{'common.no_info' | translate}}</span></span>
                </div>
                <div class="d-flex justify-content-between ">
                  <span>{{'common.address' | translate}}...</span>
                  <span>{{shipment.pickup_address }}</span>
                </div>
                <div class="d-flex justify-content-between ">
                  <span>{{'common.location' | translate}}...</span>
                  <span>{{shipment.pickup_zip }} {{shipment.pickup_location }}</span>
                </div>
              </div>
              <div class="d-flex flex-column gap-2 mb-2">
                <button class="btn-primary dark big w-100"><a
                    (click)="openInGoogleMaps(shipment.pickup_address, shipment.pickup_zip, shipment.pickup_location)"><mat-icon>map</mat-icon>
                    {{'common.maps' | translate}}</a>
                </button>
                <button class="btn-primary dark big w-100"><a
                    [href]="'tel:' + shipment.pickup_phone"><mat-icon>phone</mat-icon> {{'common.call' | translate}}
                    <!-- {{shipment.pickup_phone}} --></a>
                </button>
              </div>

              <hr class="opacity-10" style="color: lightgrey;">

              <div class="mt-2">
                <span style="font-size: 1.25rem;">{{'common.articles' | translate}}</span>
                <div class="d-flex flex-column gap-2 py-2 px-1" style="background: white; border-radius: 0.5rem;"
                  *ngFor="let packet of shipment.packets ; let i = index">
                  <div class="d-flex justify-content-between">
                    <span class="fw-bold">{{ i + 1 }}. {{'common.pallet' | translate}}</span>
                    <span>H{{packet.height }}cm L{{packet.length }}cm W{{packet.width }}cm</span>
                    <span>{{packet.weight }} kg</span>
                  </div>
                  <div>
                    <span>{{packet.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- DELIVERY -->
            <div *ngIf="type==='delivery' || type==='inVehicle'" class="">
              <div class="address-container d-flex flex-column justify-content-between mb-2">

                <div class="d-flex justify-content-between">
                  <span>{{'common.firma' | translate}}...</span>
                  <span><strong>{{shipment.delivery_company}}</strong> <span class="no-info"
                      *ngIf="!shipment.delivery_company">{{'common.no_info' | translate}}</span></span>
                </div>
                <div class="d-flex justify-content-between ">
                  <span>{{'common.address' | translate}}...</span>
                  <span>{{shipment.delivery_address }}</span>
                </div>
                <div class="d-flex justify-content-between ">
                  <span>{{'common.location' | translate}}...</span>
                  <span>{{shipment.delivery_zip }} {{shipment.delivery_location }}</span>
                </div>
              </div>
              <div class="d-flex flex-column gap-2 mb-2">
                <button class="btn-primary dark big w-100"><a
                    (click)="openInGoogleMaps(shipment.delivery_address, shipment.delivery_zip, shipment.delivery_location)"><mat-icon>map</mat-icon>
                    {{'common.maps' | translate}}</a>
                </button>
                <button class="btn-primary dark big w-100"><a
                    [href]="'tel:' + shipment.delivery_phone"><mat-icon>phone</mat-icon> {{'common.call' | translate}}
                    <!-- {{shipment.delivery_phone}} --></a>
                </button>
              </div>

              <hr class="opacity-10" style="color: lightgrey;">

              <div class="mt-2">
                <span style="font-size: 1.25rem;">{{'common.articles' | translate}}</span>
                <div class="d-flex flex-column gap-2 py-2 px-1" style="background: white; border-radius: 0.5rem;"
                  *ngFor="let packet of shipment.packets ; let i = index">
                  <div class="d-flex justify-content-between">
                    <span class="fw-bold">{{ i + 1 }}. {{'common.pallet' | translate}}</span>
                    <span>H{{packet.height }}cm L{{packet.length }}cm W{{packet.width }}cm</span>
                    <span>{{packet.weight }} kg</span>
                  </div>
                  <div>
                    <span>{{packet.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- END OF SHIPMENT -->
            <div class="d-flex flex-column">
              <div class="d-flex flex-md-row flex-column justify-content-between gap-2">
                <!-- TODO: create an entry on the DB -->
                <!-- <button class="btn-primary yellow big my-2 w-100"><mat-icon>warning</mat-icon> {{'common.report_problem' |
                  translate}}</button> -->
                <button class="btn-primary green big my-2 w-100" *ngIf="['pickup', 'delivery'].includes(type)"
                  [routerLink]="[]" (click)="close(shipment)">
                  <mat-icon>check_circle</mat-icon>
                  <a *ngIf="type=='pickup'">{{'common.complete_pick_up' | translate}}</a>
                  <a *ngIf="type=='delivery'">{{'common.delivered' | translate}}</a>
                </button>
              </div>
              <div class=""
                *ngIf="(shipment.current_loc == currentLocationStatus.AT_DELIVERY_TRUCK || shipment.current_loc == currentLocationStatus.AT_PICKUP_TRUCK) && (shipment.task == shipmentTaskStatus.PICKUP_VIA_HUB || shipment.task == shipmentTaskStatus.DELIVERY_VIA_HUB)">
                <button class="btn-primary green big w-100" (click)="setAtHub(shipment)">
                  {{'common.in_hub' | translate}} "{{shipment.assigned_hub_object?.name}}" {{'common.set' | translate}}
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
</div>