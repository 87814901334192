<div class="py-4">
  <div mat-dialog-title class="d-flex justify-content-center w-100 position-relative gap-2a">
    <div class="">
      <img style="filter: invert(100%);" src="assets/icon/truck.svg"  height="40" class="truck"/>
    </div>
    <h2 *ngIf="!data.uuid">{{'common.add_vehicle' | translate}}</h2>
    <h2 *ngIf="!!data.uuid">{{'common.edit_vehicle' | translate}}</h2>
    <button class="btn-primary dark small-icon primary-hover-animation position-absolute end-0 mx-4"  mat-dialog-close="">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="mt-2">
  
      <form [formGroup]="form">
  
        <div class="w-100 d-flex gap-3">
          <mat-form-field class="w-50" appearance="outline">
            <mat-label>{{'common.truck_number' | translate}}</mat-label>
            <input matInput formControlName="number">
          </mat-form-field>
    
          <!-- TODO: backend -->
          <mat-form-field class="w-50" appearance="outline">
            <mat-label>Plate Number</mat-label>
            <input matInput >
          </mat-form-field>
        </div>
  
        <div class="w-100 d-flex gap-3">
          <!-- TODO: backend -->
          <mat-form-field class="w-50" appearance="outline">
            <mat-label>Brand</mat-label>
            <input matInput >
          </mat-form-field>

          <mat-form-field class="w-50" appearance="outline">
            <mat-label>{{'common.model' | translate}}</mat-label>
            <input matInput formControlName="model">
          </mat-form-field>
        </div>

        <div class="w-100 d-flex gap-3">
          <mat-form-field class="w-50" appearance="outline" >
            <mat-label>{{'carrier_dashboard.user-editor-payload' | translate}} kg</mat-label>
            <input matInput formControlName="max_weight">
          </mat-form-field>


          <mat-form-field class="w-50" appearance="outline">
            <mat-label>{{'carrier_dashboard.number_of_slots' | translate}}</mat-label>
            <input matInput formControlName="max_ground_spaces">
          </mat-form-field>
        </div>

        <div class="w-100 d-flex gap-3">
          <!-- TODO: backend -->
          <mat-form-field style="width: 33.3333%;" appearance="outline">
            <mat-label>Consumption</mat-label>
            <input matInput >
          </mat-form-field>

          <!-- TODO: backend -->
          <mat-form-field style="width: 33.3333%;" appearance="outline">
            <mat-label>Insurance</mat-label>
            <input matInput >
          </mat-form-field>

          <!-- TODO: backend -->
          <mat-form-field style="width: 33.3333%;" appearance="outline">
            <mat-label>Leasing</mat-label>
            <input matInput>
          </mat-form-field>

        </div>
  
  
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'carrier_dashboard.user-editor-description' | translate}}</mat-label>
          <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="10" formControlName="description"></textarea>
        </mat-form-field>
  
      </form>
  
    </div>
  
  </div>
  <div class="mb-3 d-flex flex-column justify-content-between gap-4" mat-dialog-actions>
    <div class="d-flex justify-content-between gap-4">
      <!-- TODO: backend -->
      <button class="btn-primary dark primary-hover-animation">Vehicle Registration</button>
      <button class="btn-primary dark primary-hover-animation">Insurance Policy</button>
      <button class="btn-primary dark primary-hover-animation">Leasing Contract</button>
    </div>
    <button class="btn-primary green big primary-hover-animation" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
  </div>
</div>
