<div class="container-fluid">
    <div class="page-header-container container">
        <h2>{{'sender.dashboard.main_title' | translate}}</h2>

        <div class="buttons-container">
            <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
            <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </a>
        </div>

    </div>

    <div class="container custom-dashboard">

        <div class="row justify-content-center">

            <div class="col-md-12 col-lg-4 "
                *ngIf="organizationsService.ownOrg$ | async"
                (click)="navigateToMarketPlace()"
                >
                <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-4">
                    <mat-card-content>
                        {{"carrier_dashboard.shipments_in_market_place" | translate}}
                        <br>
                        <div class="d-flex gap-2 justify-content-center align-items-center">
                            <span class="flex" style="font-size: 4rem; font-weight: 600;">{{marketplaceShipments.length}}</span>
                            <mat-icon>sell</mat-icon>
                        </div>
                        <!-- <small>für mich</small> -->
                    </mat-card-content>
                </mat-card>
            </div>


            <div class="col-md-12 col-lg-4 "
                *ngIf="usersService.ownUser$ | async" [routerLink]="['/main/shipper/shipment-list']">
                <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-4">
                    <mat-card-content>
                        {{'carrier_dashboard.all_shipments' | translate}}
                        <br>
                        <div class="d-flex gap-2 justify-content-center align-items-center">
                            <span style="font-size: 4rem; font-weight: 600;"
                                >{{shipments.length}}</span>
                                <mat-icon>all_inbox</mat-icon>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>

    </div>

    <div class="container">

        <div class="container mt-4 mb-4 text-center">
            <a [routerLink]="['/main/shipper/wizard']" style="width: max-content;"
                class="btn-primary big green primary-hover-animation mx-auto">
                <mat-icon>add_to_photos</mat-icon>
                {{'sender.dashboard.shipment' | translate}}
            </a>
        </div>

    </div>
</div>