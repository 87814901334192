import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { APP_NAME, APP_VERSION, AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularFireModule } from '@angular/fire/compat';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { QRCodeModule } from 'angularx-qrcode';
import { MatRadioModule } from '@angular/material/radio';

import { MatCardModule } from '@angular/material/card'
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import * as Sentry from "@sentry/angular-ivy";

import { versions } from 'projects/versions';

import { AppComponent } from './app.component';
import { environment } from './environments/environment.prod';
import { LoginComponent } from './pages/public/login/login.component';
import { ResetPasswordComponent } from './pages/public/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './pages/public/forget-password/forget-password.component';
import { RegisterComponent } from './pages/public/register/register.component';
import { PublicFooterComponent } from './pages/public/layout/footer/footer.component';
import { PublicHeaderComponent } from './pages/public/layout/header/header.component';
import { PublicLayoutComponent } from './pages/public/layout/public/public.component';
import { ToastrModule } from 'ngx-toastr';
import { CarrierDashboardComponent } from './pages/carrier/dashboard/dashboard.component';
import { AboutComponent } from './pages/carrier/about/about.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ClientsOverviewComponent } from './pages/carrier/client-overview/client-overview.component';
import { ClientEditorComponent } from './components/client-editor/client-editor.component';
import { OrganizationDetailsComponent } from './pages/carrier/organization-details/organization-details.component';
import { ProfileComponent } from './pages/carrier/profile/profile.component';
import { CompagnoAvatarModule } from './modules/avatar/avatar.module';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { Router } from '@angular/router';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token/refresh-token.interceptor';
import { ErrorInterceptor } from './interceptors/error/error.interceptor';
import { AppVersionInterceptor } from './interceptors/app-version/app-version.interceptor';
import { UserManagementComponent } from './pages/carrier/user-management/user-management.component';
import { AvatarViewComponent } from './components/avatar-view/avatar-view.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { WareHouseManagementComponent } from './pages/carrier/warehouse-management/warehouse-management.component';
import { PipelineDispatchComponent } from './pages/carrier/pipeline-dispatch/pipeline-dispatch.component';
import { PipelineReleaseComponent } from './pages/carrier/pipeline-release/pipeline-release.component';
import { ShipmentRegistrationComponent } from './pages/carrier/shipment-registration/shipment-registration.component';
import { EmptiesComponent } from './pages/carrier/empties/empties.component';
import { EnumIntToDescriptionPipe } from './services/enum-int-string.pipe';
import { SettingsComponent } from './pages/carrier/settings/settings.component';
import { AccountingInvoiceEditorComponent } from './pages/carrier/facturation/accounting-invoice-editor/accounting-invoice-editor.component';
import { FacturationComponent } from './pages/carrier/facturation/accounting/accounting.component';
import { InvoiceEditModalComponent } from './pages/carrier/facturation/invoice-edit-modal/invoice-edit-modal.component';
import { AccountingInvoiceListComponent } from './pages/carrier/facturation/accounting-invoice-list/accounting-invoice-list.component';
import { InvoiceStatusPipe } from './pipes/invoice-status.pipe';
import { ActivityComponent } from './pages/carrier/activity/activity.component';
import { AlertsComponent } from './pages/carrier/alerts/alerts.component';
import { MarketPlaceComponent } from './pages/carrier/market-place/market-place.component';
import { SiteKeyEditorComponent } from './components/site-key-editor/site-key-editor.component';
import { ExportModalComponent } from './components/export/export.component';
import { SurchargePricingFormComponent } from './components/surcharge-pricing-form/surcharge-pricing-form.component';
import { ShipmentDetailsComponent, ShipmentStatusModal } from './pages/carrier/shipment-details/shipment-details.component';
import { ShipmentPacketsComponent } from 'projects/webapp/app/components/shipment-packets/shipment-packets.component';
import { LocationFormComponent } from 'projects/shared/location-form/location-form.component';
import { WizardComponent } from 'projects/shared/wizard/wizard.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ShipmentNumberPipe } from './pipes/shipment-number.pipe';
import { PricingFormComponent } from './components/pricing-form/pricing-form.component';
import { ShipmentCurrentLocationStatusPipe, ShipmentStatusPipe, ShipmentTaskStatusPipe } from './pipes/shipment-status.pipe';
import { HumanDatePipe } from './pipes/human-date.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { CarrierSearchComponent } from './pages/carrier/shipment-search/shipment-search.component';
// import { NgxTimelineModule } from '@frxjs/ngx-timeline';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ClientSelectorModalComponent } from './components/client-selector-modal/client-selector.component';
import { ClientDetailsComponent } from './pages/carrier/client-details/client-details.component';
import { ShipmentTrackingPageComponent } from './pages/public/shipment-tracking/shipment-tracking.component';
import { ShipmentEventTypePipe } from './pipes/shipment-event-type.pipe';
import { ShipmentTrackingTimelineComponent } from './pages/public/shipment-tracking-timeline/shipment-tracking-timeline.component';
import { ShipmentCommentModal } from './components/shipment-comment-modal.component';
import { UserSelectorModalComponent } from './components/user-selector-modal/user-selector.component';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';
import { ShipmentEditPickupComponent } from './pages/carrier/shipment-edit-pickup/shipment-edit-pickup.component';
import { ShipmentEditDeliveryComponent } from './pages/carrier/shipment-edit-delivery/shipment-edit-delivery.component';
import { ShipmentEditPricingModalComponent } from './components/shipment-edit-pricing-modal/shipment-edit-pricing-modal.component';
import { ShipmentEditCargoItemsComponent } from './pages/carrier/shipment-edit-cargo-items/shipment-edit-cargo-items.component';
import { ShipmentEditEmptiesComponent } from './pages/carrier/shipment-edit-empties/shipment-edit-empties.component';
import { ShipmentAssignedComponent } from './pages/driver/shipments-assigned/shipments-assigned.component';
import { ShipmentScannerComponent } from './pages/carrier/shipment-scanner/shipment-scanner.component';
import { DebugComponent } from './pages/carrier/debug/debug.component';
import { ShipmentListComponent } from './pages/carrier/shipment-list/shipment-list.component';
import { ShipmentDoDeliverComponent } from './pages/driver/shipment-do-deliver/shipment-do-deliver.component';
import { ShipmentDoPickupComponent } from './pages/driver/shipment-do-pickup/shipment-do-pickup.component';
import { FleetComponent } from './pages/carrier/fleet/fleet.component';
import { MapsComponent } from './pages/carrier/maps/maps.component';
import { MapComponent } from './components/map/map.component';
import { HubsComponent } from './pages/carrier/hubs/hubs.component';
import { HubEditorComponent } from './components/hub-editor/hub-editor.component';
import { HubSelectorComponent } from './components/hub-selector/hub-selector.component';
import { DispatchModalComponent } from './components/dispatch-modal/dispatch-modal.component';
import { PipelineInvoiceComponent } from './pages/carrier/pipeline-invoice/pipeline-invoice.component';
import { InvoiceDetailsComponent } from './pages/carrier/facturation/invoice-details/invoice-details.component';
import { UserDetailsComponent } from './pages/carrier/user-details/user-details.component';
import { SiteKeyInterceptor } from './interceptors/sitekey.interceptor';
import { EmbedWizardComponent } from './pages/embed/big-wizard/wizard.component';
import { EmbedTrackTraceComponent } from './pages/embed/track-trace/track-trace.component';
import { EmbedLocationFormComponent } from './pages/embed/big-wizard/location-form/location-form.component';
import { ShipmentTrackingComponent } from './components/shipment-tracking/shipment-tracking.component';
import { PanelModalComponent } from './components/panel-modal/panel-modal.component';
import { PanelChooserComponent } from './pages/public/panel-chooser/panel-chooser.component';
import { ShipperDashboardComponent } from './pages/shipper/dashboard/dashboard.component';
import { ShipperSearchComponent } from './pages/shipper/shipment-search/shipment-search.component';
import { ShipperMarketPlaceComponent } from './pages/shipper/market-place/market-place.component';
import { ShipperShipmentListComponent } from './pages/shipper/shipment-list/shipment-list.component';
import { ShipperWizardComponent } from './pages/shipper/shipment-wizard/shipment-wizard.component';
import { TenderPricingModalComponent } from './components/tender-pricing-modal/tender-pricing-modal.component';
import { ShipperDockYardManagementComponent } from './pages/shipper/dock-yard-management/dock-yard-management.component';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { ShipperShipmentDetailsComponent } from './pages/shipper/shipment-details/shipment-details.component';
import { OrgOverviewComponent } from './components/org-overview/org-overview.component';
import { MarketPlaceShipmentOverviewOverviewComponent } from './components/market-place-shipment-overview/market-place-shipment-overview.component';
import { CargoItemName } from './pipes/cargo-item-name.pipe';
import { ClientShipmentsComponent } from './components/client-shipments/client-shipments.component';
import { ClientKeysComponent } from './components/client-keys/client-keys.component';
import { ApiKeyEditorComponent } from './components/api-key-editor/api-key-editor.component';
import { UserEditorComponent, UserPasswordEditorComponent } from './components/user-editor/user-editor.component';
import { ClientDetailsIntegrationsComponent } from './pages/carrier/client-details-integration/client-details-integration.component';
import { ClientDetailsShipmentsComponent } from './pages/carrier/client-details-shipments/client-details-shipments.component';
import { ClientWizardsComponent } from './components/client-wizards/client-wizards.component';
import { ClientWizardComponent } from './pages/client/wizard/client-wizard.component';
import { MainComponent } from './pages/main/layout/main/main.component';
import { HeaderComponent } from './pages/main/layout/header/header.component';
import { MenuItemComponent } from './pages/main/layout/menu-item/menu-item.component';
import { OrganizationSelectorComponent } from './components/organization-selector/organization-selector.component';
import { AdminDashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { PricePipe } from './pipes/price.pipe';
import { AdminOrganizationsComponent } from './pages/admin/organizations/organizations.component';
import { AdminShipmentsComponent } from './pages/admin/shipments/shipments.component';
import { AdminUsersComponent } from './pages/admin/users/users.component';
import { CargoFlowForYouComponent } from './pages/carrier/settings-cargoflow-for-you/settings-cargoflow-for-you.component';
import { SettingsPlatformPricingComponent } from './pages/carrier/settings-platform-pricing/settings-platform-pricing.component';
import { SettingsPlatformSurchargesComponent } from './pages/carrier/settings-platform-surcharges/settings-platform-surcharges.component';
import { AdminShipmentSearchComponent } from './pages/admin/shipment-search/shipment-search.component';
import { AdminShipmentDetailsComponent } from './pages/admin/shipment-details/shipment-details.component';
import { ShipmentAddImageModalComponent } from './components/shipment-add-image-modal/shipment-add-image-modal.component';
import { ShipmentImagesComponent } from './components/shipment-images/shipment-images.component';
import { ShipmentAddressComponent } from './components/shipment-address/shipment-address.component';
import { LicenseModalComponent } from './components/license-modal/license-modal.component';
import { ValidateEmailComponent } from './pages/public/validate-email/validate-email.component';
import { MarketPlaceShipmentCardComponent } from './pages/carrier/market-place-shipment-card/market-place-shipment-card.component';
import { CaptchaModule } from './modules/captcha/captcha.module';
import { UserEditorFormComponent } from './components/user-editor-form/user-editor-form.component';
import { ShipmentEventsComponent } from './components/shipment-events/shipment-events.component';
import { DriverDashboardComponent } from './pages/driver/dashboard/dashboard.component';
import { PriceModePipe } from './pipes/price-mode.pipe';
import { DriverShipmentDetailsComponent } from './pages/driver/shipment-details/shipment-details.component';

import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { AdvertisementCardComponent } from './components/advertisement-card/advertisement-card.component';
import { AdvertisementEditorComponent } from './components/advertisement-editor/advertisement-editor.component';
import { AdvertisementDetailsComponent } from './components/advertisement-details/advertisement-details.component';
import { AdvertisementImagesComponent } from './components/advertisement-images/advertisement-images.component';
import { AdvertisementAddImageModalComponent } from './components/advertisement-add-image-modal/advertisement-add-image-modal.component';
import { AdminOrganizationDetailsComponent } from './pages/admin/organization-details/organization-details.component';
import { AdminUserDetailsComponent } from './pages/admin/user-details/user-details.component';
import { AdminAuctionsComponent } from './pages/admin/auctions/auctions.component';
import { MarketStatusPipe } from './pipes/market-status.pipe';
import { AdminTransactionsComponent } from './pages/admin/transactions/transactions.component';
import { TransactionTypePipe } from './pipes/transaction-type.pipe';
import { ShipmentPricingComponent } from './components/shipment-pricing/shipment-pricing.component';
import { ShipmentEditSurchargeModalComponent } from './components/shipment-edit-surcharge-modal/shipment-edit-surcharge-modal.component';
import { AdminActivityComponent } from './pages/admin/activity/activity.component';
import { OrganizationOverviewModalComponent } from './pages/carrier/organization-overview-modal/organization-overview-modal.component';
import { ExtrasFormComponent } from 'projects/shared/extras-form/extras-form.component';
import { AdminAdvertisementComponent } from './pages/admin/advertisement/advertisement.component';
import { EmbedSmallWizardComponent } from './pages/embed/small-wizard/wizard.component';
// import { ImportShipmentsComponent } from './components/import-shipments-modal/import-shipments-modal.component';
import { DragDirective } from './services/dragDrop.directive';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { VehicleEditorComponent } from './components/vehicle-editor/vehicle-editor.component';
import { VehicleCardComponent } from './components/vehicle-card/vehicle-card.component';
import { TourManagementComponent } from './pages/carrier/tour-management/tour-management.component';
import { TourEditorModalComponent } from './components/tour-editor-modal/tour-editor-modal.component';
import { VehicleSelectorComponent } from './components/vehicle-selector/vehicle-selector.component';
import { TourCardComponent } from './components/tour-card/tour-card.component';
import { TourSelectorComponent } from './components/tour-selector/tour-selector.component';
import { TourSelectorModalComponent } from './components/tour-selector/tour-selector-modal.component';
import { TourEditorComponent } from './components/tour-editor/tour-editor.component';
import { ShipmentFilterComponent } from './components/shipments-filters/shipments-filters.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TourStatusPipe } from './pipes/tour-status.pipe';
import { ShipmentMetaDataComponent } from './components/shipment-meta-data/shipment-meta-data.component';
import { FilteredTagsComponent } from './components/filtered-tags/filtered-tags.component';
import { FilteredListComponent } from './components/filtered-list/filtered-list.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageInterceptor } from './interceptors/language/language.interceptor';
import * as de from '@angular/common/locales/de';
import { ChangeLanguageModalComponent } from './components/change-language-modal/change-language-modal.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { LanguageChangerComponent } from './components/language-changer/language-changer.component';
import { ToursArchiveComponent } from './pages/carrier/tours-archive/tours-archive.component';
import { TourDatePickerModalComponent } from './components/tour-date-picker-modal/tour-date-picker-modal.component';
import { ImportShipmentsModalComponent } from './components/import-shipments-modal/import-shipments-modal.component';
import { DriverLocationsComponent } from './pages/carrier/driver-locations/driver-locations.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const pipes = [
  YesNoPipe,
  CargoItemName,
  EnumIntToDescriptionPipe,
  InvoiceStatusPipe,
  ShipmentNumberPipe,
  ShipmentStatusPipe,
  HumanDatePipe,
  ShipmentEventTypePipe,
  ShipmentCurrentLocationStatusPipe,
  ShipmentTaskStatusPipe,
  PricePipe,
  PriceModePipe,
  TransactionTypePipe,
  MarketStatusPipe,
  TourStatusPipe,
  TimeFormatPipe
]

const embedPages = [
  EmbedTrackTraceComponent,
  EmbedWizardComponent,
  EmbedSmallWizardComponent,
  EmbedLocationFormComponent,
]

const shipperPages = [
  ShipperDashboardComponent,
  ShipperSearchComponent,
  ShipperMarketPlaceComponent,
  ShipperShipmentListComponent,
  ShipperWizardComponent,
  ShipperShipmentDetailsComponent
]

const carrierComponents = [
  ClientWizardsComponent
]

const mainComponents = [
  MainComponent,
  HeaderComponent,
  MenuItemComponent,
]

const carrierPages = [
  ClientDetailsIntegrationsComponent,
  ClientDetailsShipmentsComponent,
  CargoFlowForYouComponent,
  ClientKeysComponent,
  SettingsPlatformPricingComponent,
  SettingsPlatformSurchargesComponent,
  ImportShipmentsModalComponent,
  TourManagementComponent,
  ToursArchiveComponent
]

const clientPages = [
  ClientWizardComponent
]


const adminPages = [
  AdminDashboardComponent,
  AdminOrganizationsComponent,
  AdminShipmentsComponent,
  AdminUsersComponent,
  AdminShipmentSearchComponent,
  AdminShipmentDetailsComponent,
  AdminOrganizationDetailsComponent,
  AdminUserDetailsComponent,
  AdminAuctionsComponent,
  AdminTransactionsComponent,
  AdminActivityComponent,
  AdminAdvertisementComponent
]


const driverPages = [
  DriverDashboardComponent,
  DriverShipmentDetailsComponent,
  ShipmentDoPickupComponent,
  ShipmentDoDeliverComponent,
]

const directives = [
  DragDirective
]

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [

    ...pipes,
    ...embedPages,
    ...mainComponents,
    ...shipperPages,
    ...carrierPages,
    ...carrierComponents,
    ...clientPages,
    ...adminPages,
    ...driverPages,
    ...directives,

    AdvertisementComponent,
    AdvertisementImagesComponent,
    AdvertisementAddImageModalComponent,
    ShipmentAddImageModalComponent,
    OrganizationOverviewModalComponent,
    ShipmentAddressComponent,
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    RegisterComponent,
    MessageModalComponent,
    LicenseModalComponent,

    PublicFooterComponent,
    PublicHeaderComponent,
    PublicLayoutComponent,

    TenderPricingModalComponent,

    CarrierDashboardComponent,
    AboutComponent,
    OrganizationSelectorComponent,
    ChangeLanguageModalComponent,
    ClientsOverviewComponent,
    ClientEditorComponent,
    TourDatePickerModalComponent,
    ProfileComponent,
    OrganizationDetailsComponent,
    MarketPlaceShipmentCardComponent,
    UserManagementComponent,
    AvatarViewComponent,
    ApiKeyEditorComponent,
    UserEditorComponent,
    UserPasswordEditorComponent,
    UserCardComponent,

    HubSelectorComponent,
    DispatchModalComponent,

    WareHouseManagementComponent,

    PipelineDispatchComponent,
    PipelineReleaseComponent,
    PipelineInvoiceComponent,

    ShipmentRegistrationComponent,
    EmptiesComponent,

    VehicleCardComponent,

    ActivityComponent,
    SettingsComponent,
    InfoModalComponent,

    AccountingInvoiceEditorComponent,
    FacturationComponent,
    InvoiceEditModalComponent,
    AccountingInvoiceListComponent,
    ShipmentPricingComponent,
    UserDetailsComponent,
    AlertsComponent,
    TourSelectorComponent,
    TourSelectorModalComponent,
    MarketPlaceComponent,
    ShipmentEditSurchargeModalComponent,
    AdvertisementDetailsComponent,
    AdvertisementEditorComponent,
    SiteKeyEditorComponent,
    ExportModalComponent,
    SurchargePricingFormComponent,
    PricingFormComponent,
    ShipmentDetailsComponent,
    ShipmentEventsComponent,
    ShipmentMetaDataComponent,
    LocationFormComponent,
    ValidateEmailComponent,
    ShipmentAssignedComponent,
    ShipmentScannerComponent,
    DebugComponent,
    WizardComponent,
    CarrierSearchComponent,
    ShipmentCommentModal,
    ShipmentStatusModal,
    ClientSelectorComponent,
    ClientSelectorModalComponent,
    ExtrasFormComponent,
    ClientDetailsComponent,
    DriverLocationsComponent,
    HubEditorComponent,
    FilteredListComponent,

    PanelModalComponent,
    PanelChooserComponent,

    FleetComponent,
    VehicleEditorComponent,
    VehicleSelectorComponent,
    HubsComponent,
    MapComponent,
    MapsComponent,
    UserEditorFormComponent,
    ShipmentTrackingComponent,
    ShipmentTrackingPageComponent,
    ShipmentTrackingTimelineComponent,
    ShipmentFilterComponent,
    FilteredTagsComponent,
    TourCardComponent,
    UserSelectorModalComponent,
    UserSelectorComponent,
    ClientShipmentsComponent,

    TourEditorModalComponent,
    TourEditorComponent,

    ShipmentEditPickupComponent,
    ShipmentEditDeliveryComponent,
    ShipmentEditPricingModalComponent,
    ShipmentEditCargoItemsComponent,
    ShipmentEditEmptiesComponent,
    ShipmentListComponent,
    InvoiceDetailsComponent,

    OrgOverviewComponent,
    MarketPlaceShipmentOverviewOverviewComponent,
    ShipmentImagesComponent,
    ShipmentPacketsComponent,
    AdvertisementCardComponent,

    ShipperDockYardManagementComponent,

    LanguageChangerComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,

    DragDropModule,
    BrowserModule,

    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,

    NgxSpinnerModule,

    MatRadioModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatListModule,
    MatTooltipModule,
    MatMenuModule,
    MatToolbarModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // QRCodeModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTabsModule,
    MatCheckboxModule,
    MatTableModule,
    MatStepperModule,
    MatCardModule,
    MatInputModule,
    MatDatepickerModule,
    CaptchaModule,
    AppRoutingModule,
    CompagnoAvatarModule,
    // AvatarModule,
    ToastrModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),

    // NgxTimelineModule,
    MatSlideToggleModule,

    MatNativeDatetimeModule,
    MatDatetimepickerModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:10000',
    }),
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),

  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SiteKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true
    },

    { provide: APP_NAME, useValue: 'webapp' },
    { provide: APP_VERSION, useValue: versions.hash },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(){
    registerLocaleData(de.default); //Then register the language
  }
}
