import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  envName: 'local_dev',
  apiV1: 'api/v1/',
  baseUrl: '',
  production: true,
  uiDomain: 'https://app.cargoflow.com.pl',
  apiDomain: 'http://localhost:8000',
  firebase: {
    apiKey: "AIzaSyBCTd_TjQHpIDJvI869W-DXAXZHDhKHYic",
    authDomain: "compagno-83993.firebaseapp.com",
    projectId: "compagno-83993",
    storageBucket: "compagno-83993.appspot.com",
    messagingSenderId: "123151661118",
    appId: "1:123151661118:web:97b842ce97708c97b4b96f",
    measurementId: "G-YZHPSW8CVP"
  },
};
