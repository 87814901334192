<div class="container-fluid position-absolute">

    <!-- <div class="page-header-container container">
        <h2>{{'common.cards' | translate}}</h2>
        <div class="buttons-container">
            <a mat-icon-button color="primary" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </a>
        </div>
    </div> -->

    <form [formGroup]="formGroup">
        <mat-checkbox formControlName="current_driver_locations">{{'common.current_driver_locations' |
            translate}}</mat-checkbox>
        <mat-checkbox
            formControlName="current_shipments_pickup_locations">{{'common.current_shipments_pickup_locations'|
            translate}}</mat-checkbox>
        <mat-checkbox
            formControlName="current_shipments_delivery_locations">{{'common.current_shipments_delivery_locations' |
            translate}}</mat-checkbox>
    </form>

</div>
<app-map [pins]="pins"></app-map>


<!-- <pre>{{pins | json}}</pre> -->