<div class="page-header-container container d-flex flex-row">
  <div class="w-100">
    <h2 style="margin-left: 48px;">{{'common.dashboard' | translate}}</h2>
  </div>

  <div class="buttons-container" style="width: auto;">
    <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
    <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </a>
  </div>
</div>

<div class="container custom-dashboard">

  <div class="row">

    <div class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.preference_show_shipments_to_pickup">
      <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-4" (click)="navigateToPickup()">
        <mat-card-content>
          {{'driver_app.dashboard.pick_up' | translate}}
          <br>
          <div class="d-flex gap-2 justify-content-center align-items-center">
            <span class="flex" style="font-size: 4rem; font-weight: 600;"
              *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_to_pickup}}</span>
              <img src="assets/icon/pickup.svg"  height="60" class="truck"/>
          </div>
          <!-- <small>für mich</small> -->
        </mat-card-content>
      </mat-card>
    </div>


    <div class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.preference_show_shipments_in_vehicle">
      <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-4" (click)="navigateToShipmentsInVehicle()">
        <mat-card-content>
          {{'carrier_dashboard.in_the_vehicle' | translate}}
          <br>
          <div class="d-flex gap-2 justify-content-center align-items-center">
            <span style="font-size: 4rem; font-weight: 600;"
              *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_in_vehicle}}</span>
              <img src="assets/icon/truck.svg"  height="40" class="truck"/>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.preference_show_shipments_to_deliver">
      <mat-card class="my-2 text-center co-pointer co-dashboard-card rounded-4" (click)="navigateToDelivery()">
        <mat-card-content>
          {{'driver_app.dashboard.delivery' | translate}}
          <br>
          <div class="d-flex gap-2 justify-content-center align-items-center">
            <span style="font-size: 4rem; font-weight: 600;"
              *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_to_deliver}}</span>
              <img src="assets/icon/delivery.svg"  height="60" class="truck"/>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>

<ng-template #noSubscription>
  <img src="assets/Lock-Cargoflow.png" loading="lazy" alt="" width="50px" class="image">
</ng-template>
