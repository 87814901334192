import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  production: true,
  envName: 'prod',
  baseUrl: 'https://api.cargoflow.ch',
  apiV1: 'api/v1/',
  uiDomain: 'https://app.cargoflow.com.pl',
  apiDomain: 'https://api.cargoflow.ch',

  firebase: {
    apiKey: "AIzaSyBCTd_TjQHpIDJvI869W-DXAXZHDhKHYic",
    authDomain: "compagno-83993.firebaseapp.com",
    projectId: "compagno-83993",
    storageBucket: "compagno-83993.appspot.com",
    messagingSenderId: "123151661118",
    appId: "1:123151661118:web:9c6c848e5b545621b4b96f",
    measurementId: "G-V8718DGL3Q"
  },
  mapboxToken: 'pk.eyJ1IjoibmVwdHVuZGVzaWduIiwiYSI6ImNtMDQ2M29kdDAzd2QyanMybHZpeng2amIifQ.gcRd_8r2V4_NebGJe1bZSw'
};
