import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { versions } from 'projects/versions';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { LocationService } from 'projects/webapp/app/services/location.service';
import { OnlineService } from 'projects/webapp/app/services/online.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit {
  isLoading = true;
  public versions = versions;
  onlineStatusCheck = '';
  markers = [];
  currentPosition: any = null

  isOnline: boolean | undefined = undefined;
  hasCameraSupport: boolean | undefined = undefined;
  hasNotificationSupport: boolean | undefined = undefined;
  hasLocationSupport: boolean | undefined = undefined;

  constructor(
    public authService: AuthService,
    public router: Router,
    public onlineService: OnlineService,
    public locationService: LocationService
  ) {}

  async ngOnInit() {
    this.onlineService.onlineMode.subscribe((result) => {
      if (result) {
        this.onlineStatusCheck = 'Online';
        this.hasNetwork(true);
      } else {
        this.onlineStatusCheck = 'Offline';
        this.hasNetwork(true);
      }
    });

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
          console.log(stream.getVideoTracks());
          stream.getTracks().forEach((track) => {
            if (track.readyState === 'live') {
              track.stop();
            }
          });
          this.hasCameraSupport = true;
          // code for when none of the devices are available
        } else {
          this.hasCameraSupport = false;
          console.log('none available');
          // code for when both devices are available
        }
      })
      .catch((error) => {
        console.error('error in media devices');
        console.error(error);
        this.hasCameraSupport = undefined;
        // code for when there is an error
      });

    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      for (var i = 0; i < devices.length; i++) {
        var device = devices[i];
        if (device.kind === 'videoinput') {
          var option = document.createElement('option');
          option.value = device.deviceId;
          option.text = device.label || 'camera ' + (i + 1);

          const videoSource = document.querySelector('select#videoSource')
          if (!!videoSource) {
            videoSource.appendChild(option);
          }
        }
      }
    });

    if (Notification.permission === 'granted') {
      this.hasNotificationSupport = true;
    } else if (Notification.permission === 'denied') {
      this.hasNotificationSupport = false;
    }

    // https://stackoverflow.com/questions/48293914/geolocation-in-javascript-on-ios-safari
    // const id =
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        console.log('i\'m tracking you!');
        this.hasLocationSupport = true;
        console.log(position);
        this.currentPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          accuracy: position.coords.accuracy
        };
        await lastValueFrom(this.locationService.updateMyLocation(this.currentPosition))
      },
      (error) => {
        console.error(error);
        if (error.code === error.PERMISSION_DENIED) {
          this.hasLocationSupport = false;
          console.log('you denied me :-(');
        }
      }
    );

    // setTimeout(() => {
    //   navigator.geolocation.clearWatch(id);
    // }, 10 * 1000);
  }

  hasNetwork(online: any) {
    this.isOnline = online;
    // const element = document.querySelector('.status');
    // Update the DOM to reflect the current status
    console.log(`setting status to ${online}`);
    if (online) {
      // element.classList.remove('offline');
      // element.classList.add('online');
      this.onlineStatusCheck = 'Online';
    } else {
      // element.classList.remove('online');
      // element.classList.add('offline');
      this.onlineStatusCheck = 'Offline';
    }
  }

  stateToEmoji(state: boolean | undefined | null): any {
    const states = {
      // not checked
      undefined: '❔',
      // failed to check
      null: '⭕',
      // success
      true: '✔',
      // can't touch that
      false: '❌',
    };

    return states[`${state}`];
  }
}
