import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/webapp/app/environments/environment';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { LocationService } from 'projects/webapp/app/services/location.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-driver-locations',
  templateUrl: './driver-locations.component.html',
  styleUrls: ['./driver-locations.component.scss'],
})
export class DriverLocationsComponent implements OnInit {
  formGroup: FormGroup;
  pins: any[] = [];
  accessToken: string = environment.mapboxToken;
  // driverLocationInterval: any;
  userUUID: string | null = null;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public locationService: LocationService,
    public carrierShipmentsService: CarrierShipmentsService,
    public dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,

  ) {
    this.formGroup = new FormGroup({
      current_driver_locations: new FormControl(true),
      // current_shipments_pickup_locations: new FormControl(false),
      // current_shipments_delivery_locations: new FormControl(false),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (!!params && params['userUUID']) {
        this.userUUID = params['userUUID'];
        this.refresh();
      }
    })
  }

  async refresh(){
    try {

      this.updatePins();

      try {
        const driverPins = await this.fetchDriverLocations();
        console.log('Fetched driver locations:', driverPins);
        this.pins = this.pins.filter(pin => pin.title !== 'Driver Location'); // Remove old driver locations
        this.pins.push(...driverPins); // Add updated driver locations
        console.log('Updated driver locations:', driverPins);
      } catch (error) {
        console.error('Error updating driver locations:', error);
      }

      this.formGroup.valueChanges.subscribe(() => {
        this.updatePins();
      });
      console.log('MapComponent Pins:', this.pins);

    } catch (error) {
      // console.error('Error fetching locations', error);
    }
  }

  async updateDriverLocations(): Promise<void> {
    if (this.formGroup.value.current_driver_locations) {

    }
  }

  async updatePins(): Promise<void> {
    const pins: any[] = []; // Reset pins

    const {
      current_driver_locations,
    } = this.formGroup.value;

    if (current_driver_locations) {
      const driverPins = await this.fetchDriverLocations();
      pins.push(...driverPins);
    }

    this.pins = pins;
  }

  async fetchDriverLocations(){
    try {
      if (this.userUUID){
        let from_date = new Date();
        let to_date = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

        from_date.setTime(from_date.getTime() - oneDay * 2);
        to_date.setTime(to_date.getTime() + oneDay * 2);

        const to_date_string = to_date.toISOString().split('T')[0]
        const from_date_string = from_date.toISOString().split('T')[0]

        const driverLocations = await lastValueFrom(
          this.locationService.getDriverLocations(this.userUUID, from_date_string, to_date_string)
        );
        console.log(driverLocations, 'get drivers')
        return driverLocations.map((location: any) => ({
          lng: location.lng,
          lat: location.lat,
          // title: "Driver Location",
          // first_name: location.user_object.first_name,
          // last_name: location.user_object.last_name,
        }));
      }
    } catch (error) {
      console.error('Error fetching driver locations', error);
      return [];
    }
  }
}
