import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, map, startWith } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { ClientsService, IClient } from '../../services/clients.service';
import { MatDialog } from '@angular/material/dialog';
import { ClientEditorComponent } from '../client-editor/client-editor.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit {

  @Input() selectedClient: IClient | undefined | null = undefined;
  @Input() selectedClientUUID: string | undefined | null = null;
  @Input() isRequired: boolean = false;

  possibleClients: IClient[] = [];
  filteredClients: IClient[] = []
  clientFormControl = new FormControl('')

  @Output() onSelectedClient = new EventEmitter<IClient>()

  constructor(
    public clientsService: ClientsService,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {
    this.clientFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(val => {
      this.filteredClients = val;
    });
  }

  async ngOnInit() {
    if (this.isRequired) {
      this.clientFormControl.setValidators([Validators.required]);
      this.clientFormControl.markAllAsTouched()
    }
    this.possibleClients = await lastValueFrom(this.clientsService.getClients())
    if (!!this.selectedClientUUID) {
      this.selectedClient = this.possibleClients.find((c) => c.uuid === this.selectedClientUUID)
      this.onSelectedClient.emit(this.selectedClient)
    }
  }

  getTitle(client: IClient) {
    console.log(`getTitle(${client?.uuid}`)
    const filteredClient = this.possibleClients.find(p => p.uuid === client?.uuid);
    if (filteredClient) {
      if (!!filteredClient.company) {
        return filteredClient.company
      }
      return `${filteredClient.first_name} ${filteredClient.last_name}`
    }
    return '';
  }

  private _filter(value: string): IClient[] {
    console.log(value)
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.possibleClients.filter(client => {
        return (!!client.company && client.company.toLowerCase().includes(filterValue)) || client.first_name.toLowerCase().includes(filterValue) || client.last_name.toLowerCase().includes(filterValue)
      });
    }
    return []
  }

  clientSelected(client: IClient) {
    this.onSelectedClient.emit(client);
  }

  getClientName(client: IClient): string {
    let name = ''
    if (!!client.company) {
      name = `${client.company}`
    } else {
      name = `${client.first_name} ${client.last_name} `
    }

    if (client.location){
      name = name + ` in ${client.location}`
    }
    return name;
  }

  onAddClient() {
    const client: IClient = {
      company: '',
      first_name: '',
      last_name: '',
      address: '',
      zip_code: '',
      location: '',
      description: '',
      phone: '',
      email: '',
    }
    this.dialog.open(ClientEditorComponent, { data: client }).afterClosed().subscribe(async val => {
      if (!!val) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.client_created'));

        await this.clientsService.createClient(val).toPromise();
        this.toastrService.success(successMessage);
      }
    })
  }
}
