<div class="container-fluid">
    <mat-card class="mt-3">
        <mat-card-content>
            <div class="gap-3" style="display: flex; flex-direction: row; justify-content: start; align-items: center;">
                <h1>{{'common.export' | translate}}</h1>
                <!-- <span>({{languageService.currentLanguage}})</span> -->
            </div>

            <div>
                <tabset>
                    <tab heading="{{'common.shipments' | translate}}" id="tab1">
                        <div class="text-center mt-5">

                            <bs-daterangepicker-inline [bsValue]="bsInlineRangeValue" (bsValueChange)="onDateChange($event)"></bs-daterangepicker-inline>
                            <br>
                            <!-- <pre>{{bsInlineRangeValue | json}}</pre> -->
                            <br>
                            <button class="btn-primary green primary-hover-animation mx-auto" (click)="downloadShipmentExport()">{{'common.download'| translate}} <mat-icon>note_add</mat-icon></button>
                        </div>
                    </tab>
                    <!-- <tab heading="Kunden">
                        <div class="text-center mt-5">
                            <div class="alert alert-info">coming soon...</div>
                            <button class="btn btn-primary mt-4 mb-4" (click)="downloadClientExport()" [disabled]="true">Download</button>
                        </div>
                    </tab> -->
                </tabset>
            </div>

        </mat-card-content>
    </mat-card>
</div>
