<div class="container-fluid">
    <div class="page-header-container">
        <h2 translate>title_dispatcher_dashboard</h2>

        <div class="buttons-container">
            <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
            <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </a>
        </div>

    </div>

    <div class="alert alert-info bg-green-light mt-5 border-0"
        *ngIf="!shipmentsForInvoicing || shipmentsForInvoicing.length == 0">
        {{'carrier_dashboard.invoice_pipeline.no_shipment' | translate}}
    </div>

    <table class="table table-custom w-100"
        *ngIf="!!shipmentsForInvoicing && shipmentsForInvoicing.length > 0">
        <thead>
            <td>{{'common.number' | translate}}</td>
            <td>{{'common.client' | translate}}</td>
            <td>{{'common.from' | translate}}</td>
            <td>{{'common.to' | translate}}</td>
            <td></td>
        </thead>

        <tbody>
            <tr *ngFor="let shipment of shipmentsForInvoicing">
                <td [attr.data-label]="'common.number' | translate">
                    <span>
                        {{shipment.number | shipmentNumberPipe }}
                    </span>
                </td>
                <td [attr.data-label]="'common.client' | translate">
                    <ul class="co-simple-list" *ngIf="shipment.client_object">
                        <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                            <li>
                                {{shipment.client_object.company}}
                            </li>
                            <li>
                                {{shipment.client_object.first_name }}
                                {{shipment.client_object.last_name }}
                            </li>
                        </a>
                    </ul>
                </td>
                <td [attr.data-label]="'common.from' | translate">
                    <!-- {{shipment.pickup_address }} -->
                    <!-- <br> -->
                    <!-- {{shipment.pickup_zip }} --> {{shipment.pickup_location }}
                    <br>
                    {{shipment.pickup_date }}
                </td>

                <td [attr.data-label]="'common.to' | translate">
                    <!-- {{shipment.delivery_address }} -->
                    <!-- <br> -->
                    <!-- {{shipment.delivery_zip }}  -->{{shipment.delivery_location }}
                    <br>
                    {{shipment.delivery_date }}
                </td>
                <td>
                    <div class="d-flex justify-content-around justify-content-md-end gap-2">
                        <!-- TODO: translate -->
                        <button matTooltip="More information"
                            class="btn-primary blue big-icon mobile-text primary-hover-animation d-flex align-items-center justify-content-center"
                            routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                            <mat-icon>info</mat-icon>
                            <span>Info</span>
                        </button>
                        <button [matTooltip]="'common.send_to_invoice' | translate"
                            class="btn-primary green big-icon mobile-text primary-hover-animation"
                            (click)="sendShipmentToInvoicingSystem(shipment)">
                            <mat-icon>check</mat-icon>
                            <span>{{'common.send_to_invoice' | translate}}</span>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

</div>