import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { versions } from 'projects/versions';
import { LicenseModalComponent } from 'projects/webapp/app/components/license-modal/license-modal.component';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { LocationService } from 'projects/webapp/app/services/location.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import {  UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-driver-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DriverDashboardComponent implements OnInit {

  versions = versions;
  isLoading = false;

  dashboardNumbers: {
    shipments_for_approval: number,
    shipments_in_market_place: number,
    shipments_in_alert: number,
    shipments_to_pickup: number,
    shipments_to_deliver: number,
    shipments_in_vehicle: number,
  } | null = null;

  constructor(
    private router: Router,
    private title: Title,
    private carrierShipmentsService: CarrierShipmentsService,
    public organizationsService: OrganizationsService,
    public usersService: UsersService,
    private dialog: MatDialog,
    private locationService: LocationService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.dashboard').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.dashboardNumbers = await lastValueFrom(this.carrierShipmentsService.getDashboardNumbers())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }

    try {
      if (navigator.geolocation) {
        // https://stackoverflow.com/questions/48293914/geolocation-in-javascript-on-ios-safari
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              console.log('i\'m tracking you!');
              // this.hasLocationSupport = true;
              console.log(position);
              const currentPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                accuracy: position.coords.accuracy
              };
              await lastValueFrom(this.locationService.updateMyLocation(currentPosition))
            } catch (error) {
              console.error(error)
            }
          },
          (error) => {
            console.error(error);
            if (error.code === error.PERMISSION_DENIED) {
              // this.hasLocationSupport = false;
              console.error('you denied me :-(');
            }
          }
        );
      }
    } catch (error) {
      console.error(error)
    }
  }

  navigateToPickup() {
    if (!this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
      this.dialog.open(LicenseModalComponent,
        { disableClose: true })
      return
    }
    this.router.navigate(['/main/driver/shipments-assigned/pickup'])
  }

  navigateToShipmentsInVehicle() {
    if (!this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
      this.dialog.open(LicenseModalComponent,
        { disableClose: true })
      return
    }
    this.router.navigate(['/main/driver/shipments-assigned/inVehicle'])
  }

  navigateToDelivery() {
    if (!this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
      this.dialog.open(LicenseModalComponent,
        { disableClose: true })
      return
    }
    this.router.navigate(['/main/driver/shipments-assigned/delivery'])
  }
}
