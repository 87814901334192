<div class="container-fluid position-absolute">

    <!-- <form [formGroup]="formGroup">
        <mat-checkbox formControlName="current_driver_locations">{{'common.current_driver_locations' |
            translate}}</mat-checkbox>
        <mat-checkbox
            formControlName="current_shipments_pickup_locations">{{'common.current_shipments_pickup_locations'|
            translate}}</mat-checkbox>
        <mat-checkbox
            formControlName="current_shipments_delivery_locations">{{'common.current_shipments_delivery_locations' |
            translate}}</mat-checkbox>
    </form> -->

</div>
<app-map [pins]="pins"></app-map>

<!-- <pre>{{pins | json}}</pre> -->
