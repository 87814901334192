<div class="container-fluid">

  <div class="page-header-container">
    <h2>{{'carrier_dashboard.release_pipeline.main_title' | translate}}</h2>

    <div class="buttons-container">
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
      <!-- TODO: translate -->
      <button matTooltip="Import exel file with new shipments" class="btn-primary green primary-hover-animation"
        style="width: fit-content;" mat-flat-button color="primary" (click)="import()">
        import
      </button>
      <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
    </div>

  </div>


  <div class="alert alert-info text-center  mt-5" *ngIf="!shipmentsForApproval || shipmentsForApproval.length == 0">
    {{'carrier_dashboard.accept_shipments.no-shipment' | translate}}
  </div>

  <div *ngIf="!!shipmentsForApproval && shipmentsForApproval.length > 0">
    <table class="table table-custom w-100">
      <thead>
        <tr>
          <th>{{'common.number' | translate}}</th>
          <th>{{'common.client' | translate}}</th>
          <th>{{'common.price'| translate}}</th>
          <th>{{'common.from'| translate}}</th>
          <th>{{'common.to'| translate}}</th>
          <th></th>
        </tr>
        <tr class="top-fade">
          <th colspan="6"></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let shipment of shipmentsForApproval">
          <td [attr.data-label]="'common.number' | translate">
            <span>
              {{shipment.number | shipmentNumberPipe }}
            </span>
          </td>
          <td [attr.data-label]="'common.client' | translate">
            <ul class="co-simple-list" *ngIf="shipment.client_object">
              <li>
                <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                  <span *ngIf="shipment.client_object.company; else noClient">
                    <strong>{{shipment.client_object.company }}</strong>
                  </span>
                  <ng-template #noClient>
                    {{shipment.client_object.first_name }}
                    {{shipment.client_object.last_name }}
                  </ng-template>
                </a>
              </li>
              <li>
                <a routerLink="/main/carrier/clients/{{shipment.client_object.uuid}}/details">
                </a>
              </li>
            </ul>
            <span class="opacity-50" *ngIf="!shipment.client_object">{{'common.no_client_assingned' | translate}}</span>
          </td>
          <td [attr.data-label]="'common.price' | translate" name="Preis">
            <strong>{{ownOrganization?.default_currency}} {{shipment.total_price | pricePipe}}</strong>
          </td>
          <td [attr.data-label]="'common.from' | translate" name="Abholadresse">
            <span *ngIf="shipment.pickup_company">
              <strong>{{shipment.pickup_company }}</strong>
              <!-- <br> -->
            </span>
            <!-- <span *ngIf="shipment.pickup_name">
              {{shipment.pickup_name }}
              <br>
            </span> -->
            <!-- {{shipment.pickup_address }} -->
            <br>
            <!-- {{shipment.pickup_zip }}  -->{{shipment.pickup_location }}
            <br>
            {{shipment.pickup_date }}
          </td>

          <td [attr.data-label]="'common.to' | translate" name="Zustelladresse">
            <span *ngIf="shipment.delivery_company">
              <strong>{{shipment.delivery_company }}</strong>
              <!-- <br> -->
            </span>
            <!-- <span *ngIf="shipment.delivery_name">
              {{shipment.delivery_name }}
              <br>
            </span> -->

            <!-- {{shipment.delivery_address }} -->
            <br>
            <!-- {{shipment.delivery_zip }} --> {{shipment.delivery_location }}
            <br>
            {{shipment.delivery_date }}
          </td>
          <td name="Aktionen">
            <div class="d-flex justify-content-around justify-content-md-end gap-2">
              <!-- TODO: translate -->
              <button matTooltip="More information"
                class="btn-primary blue big-icon mobile-text primary-hover-animation d-flex align-items-center justify-content-center"
                routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                <mat-icon>info</mat-icon>
                <span>Info</span>
              </button>

              <!-- TODO: translate -->
              <button matTooltip="Sell in the marketplace"
                class="btn-primary yellow big-icon mobile-text primary-hover-animation d-flex align-items-center justify-content-center"
                (click)="sellShipment(shipment)">
                <mat-icon>sell</mat-icon>
                <span>{{'common.sell' | translate}}</span>
              </button>

              <button [matTooltip]="'common.accept_shipment' | translate"
                class="btn-primary green big-icon mobile-text primary-hover-animation d-flex align-items-center justify-content-center"
                (click)="acceptShipment(shipment)">
                <mat-icon>check_circle</mat-icon>
                <!-- TODO: translate -->
                <span>Accept</span>
              </button>
            </div>
          </td>

        </tr>
      </tbody>
    </table>
  </div>

  <div class="bottom-container-fade">
    <div class="fade-container"></div>
    <div class="button-wrapper d-flex justify-content-end gap-4">
      <button class="green-btn primary-hover-animation d-flex align-items-center gap-2 justify-content-center"
        (click)="acceptAll()">
        <mat-icon>thumb_up_alt</mat-icon>
        {{'common.accept_all' | translate}}
      </button>
      <a routerLink="/main/carrier/pipeline-dispatch"
        class="btn-primary dark primary-hover-animation"><mat-icon>keyboard_double_arrow_right</mat-icon>
        {{'common.disposition' | translate}}</a>
    </div>
  </div>
</div>