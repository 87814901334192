<div class="co-card">
    <div class="p-4" *ngIf="!!packetForm">
        <h5 class="card-title d-flex justify-content-between pb-2" style="align-items: center;">
            <span>
                {{'common.freight' | translate}} {{index + 1}}
            </span>

            <button class="delete-btn primary-hover-animation" (click)="remove()" [disabled]="number_of_packets==1">
                <mat-icon>delete_forever</mat-icon>
            </button>

        </h5>
        <form [formGroup]="packetForm">

            <div>
                <div class="mb-3">
                    <div class="form-floating">
                        <input type="number" class="form-control" placeholder="Anzahl" formControlName="count" min="1"
                            [ngClass]="{'is-valid': packetForm.get('count')?.touched && packetForm.get('count')?.valid, 'is-invalid': packetForm.get('count')?.touched && packetForm.get('count')?.invalid}" />
                        <label [for]="'count'" class="form-label" translate>common.quantity</label>
                        <div class="invalid-feedback"
                            *ngIf="!!packetForm && packetForm.get('count') && packetForm.get('count').invalid && packetForm.get('count').touched" translate>
                            carrier_dashboard.field_not_valid
                        </div>
                    </div>
                    <!-- <pre *ngIf="packetForm.get('count')?.invalid">invalid {{packetForm.get('count').invalid}}</pre> -->
                    <!-- <pre *ngIf="packetForm.get('count')?.touched">touched {{packetForm.get('count').touched}}</pre> -->
                </div>

                <div class="mb-3">
                    <select class="form-select" formControlName="type">
                        <option value="1" translate>common.europallet</option>
                        <option value="2" translate>common.disposable_pallet</option>
                        <option value="3" translate>common.carton</option>
                        <option value="4" translate>common.another</option>
                    </select>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input type="text" placeholder="Inhalt" class="form-control" formControlName="name"
                            [ngClass]="{'is-valid': packetForm.get('name')?.touched && packetForm.get('name')?.valid, 'is-invalid': packetForm.get('name')?.touched && packetForm.get('name')?.invalid}">
                        <label [for]="'name'" class="form-label" translate>carrier_dashboard.product_description</label>
                        <div class="invalid-feedback"
                            *ngIf="!!packetForm && packetForm.get('name') && packetForm.get('name').invalid && packetForm.get('name').touched" translate>
                            carrier_dashboard.field_not_valid
                        </div>

                    </div>
                    <!--
                        <pre *ngIf="packetForm.get('name')?.invalid">invalid {{packetForm.get('name').invalid}}</pre>
                    <pre *ngIf="packetForm.get('name')?.touched">touched {{packetForm.get('name').touched}}</pre>
                 -->
                </div>

            </div>
            <div>
                <div class="input-group mb-3">
                    <div class="form-floating">
                        <input type="number" id="length" class="form-control" formControlName="length" [ngClass]="{'is-valid': packetForm.get('length')?.touched && packetForm.get('length')?.valid, 'is-invalid': packetForm.get('length')?.touched && packetForm.get('length')?.invalid}">
                        <label for="length" class="form-label">{{'common.length' | translate}} [cm]</label>
                    </div>

                    <div class="form-floating">
                        <input type="number" id="width" class="form-control" formControlName="width" [ngClass]="{'is-valid': packetForm.get('width')?.touched && packetForm.get('width')?.valid, 'is-invalid': packetForm.get('width')?.touched && packetForm.get('width')?.invalid}">
                        <label for="width" class="form-label">{{'common.width' | translate}} [cm]</label>
                    </div>

                    <div class="form-floating">
                        <input type="number" id="height" class="form-control" formControlName="height" [ngClass]="{'is-valid': packetForm.get('height')?.touched && packetForm.get('height')?.valid, 'is-invalid': packetForm.get('height')?.touched && packetForm.get('height')?.invalid}">
                        <label for="height" class="form-label">{{'common.height' | translate}} [cm]</label>
                    </div>
                </div>

            </div>
            <div>
                <div class="form-floating mb-3">
                    <input type="number" class="form-control" id="exampleInputEmail1" formControlName="weight" [ngClass]="{'is-valid': packetForm.get('weight')?.touched && packetForm.get('weight')?.valid, 'is-invalid': packetForm.get('weight')?.touched && packetForm.get('weight')?.invalid}">
                    <label for="exampleInputEmail1" class="form-label">{{'common.weight' | translate}} [kg]</label>
                </div>
            </div>

        </form>

        <!-- <pre>{{packetForm.value | json}}</pre> -->
    </div>

</div>
