<div class="container-fluid">

    <div class="page-header-container container">
        <h2>{{'carrier_dashboard.invoicing.main-title' | translate}}</h2>

        <!-- <div class="buttons-container">
          <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
        </div> -->

    </div>

    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" class="mt-1 mb-1">
        <mat-tab [label]="'carrier_dashboard.invoicing.ready_for_facturation' | translate">
            <div style="display: flex; flex-direction: row; justify-content: end;">

                <div>
                    <button mat-icon-button color="primary" (click)="refresh()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>

            <div *ngIf="!!clientShipments && clientShipments.length == 0" class="alert alert-info mt-3">
                {{'carrier_dashboard.invoicing.no-shipments' | translate}}
            </div>

            <table *ngIf="!!clientShipments && clientShipments.length > 0" style="width: 100%"
                class="table table-custom">
                <thead class="thead-light">
                    <td>{{'common.number' | translate}}</td>
                    <td>{{'common.price'| translate}}</td>
                    <td style="display: flex; justify-content: center;"> </td>
                </thead>

                <tbody>
                    <ng-container *ngFor="let client of clientShipments">
                        <!-- <pre>{{client | json}}</pre> -->
                        <tr class="table-success">
                            <td>
                                {{'common.client' | translate}}:
                                <a routerLink="/main/carrier/clients/{{client.client_object.uuid}}/details">
                                    <strong>{{client.client_object.company}}</strong>
                                </a>
                            </td>
                            <td>{{'common.total' | translate}}
                                <strong>{{ownOrganization?.default_currency}} {{client.client_total_price | number:
                                    '1.2-2'}}</strong>
                            </td>

                            <td>
                                <a [matTooltip]="'carrier_dashboard.facturation.generate_invoice' | translate" class="btn-primary green big-icon mobile-text primary-hover-animation d-flex ms-auto gap-2"
                                    [routerLink]="[]" (click)="invoiceClient(client.client_object)">
                                    <mat-icon class="fs-sm-2">assignment_turned_in</mat-icon>
                                    <span>{{'carrier_dashboard.facturation.generate_invoice' | translate}}</span>
                                </a>
                            </td>

                        </tr>
                        <tr *ngFor="let shipment of client.shipments">
                            <td [attr.data-label]="'common.number' | translate">
                                <span>
                                    {{shipment.number | shipmentNumberPipe}}
                                </span>
                            </td>
                            <td [attr.data-label]="'common.price' | translate">
                                <div class="text-end">
                                    {{ownOrganization?.default_currency}} {{shipment.total_price | number: '1.2-2'
                                    }}
                                </div>
                            </td>
                            <td class="d-flex justify-content-around justify-content-md-end gap-2">
                                <!-- TODO: translate -->
                                <button matTooltip="More information"
                                    class="btn-primary blue big-icon mobile-text primary-hover-animation d-flex align-items-center justify-content-center"
                                    routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                                    <mat-icon>info</mat-icon>
                                    <span>Info</span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>

            <!-- <pre>{{clientShipments | json}}</pre> -->
        </mat-tab>
        <mat-tab [label]="'carrier_dashboard.facturation.open_invoices' | translate">
            <ng-template matTabContent>
                <app-accounting-invoice-list
                    [invoiceType]="[invoiceStatus.DRAFT, invoiceStatus.SENT]"></app-accounting-invoice-list>
            </ng-template>
        </mat-tab>
        <mat-tab [label]="'carrier_dashboard.facturation.paid_invoices' | translate">
            <ng-template matTabContent>
                <app-accounting-invoice-list
                    [invoiceType]="[invoiceStatus.PAID, invoiceStatus.CANCELLED]"></app-accounting-invoice-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>