<div class="container-fluid">

  <div class="page-header-container container">
    <h2>{{'title_dispatcher_dashboard' | translate}}</h2>

    <div class="buttons-container">
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
      <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
    </div>

  </div>

  <div class="container custom-dashboard">
    <div class="row">

      <div class="card-dashboard col-md-12 col-lg-4"
        *ngIf="(usersService.ownUser$ | async)?.permission_market_place && (organizationsService.ownOrg$ | async)?.have_market_place && (usersService.ownUser$ | async)?.preference_show_shipments_to_pickup">
        <mat-card class="mb-4 mt-4 text-center co-pointer co-dashboard-card primary-hover-animation"
          (click)="navigateToMarketPlace()">
          <mat-card-content>

            {{"carrier_dashboard.market_place" | translate}}
            <br>
            <div class="d-flex gap-2 justify-content-center align-items-center">
              <span>{{dashboardNumbers?.shipments_in_market_place}}</span><img src="assets/icon/boxes.svg" />
            </div>
            <small>{{"carrier_dashboard.shipments_in_market_place" | translate}}</small>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="card-dashboard col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.permission_pipeline">
        <mat-card class="mb-4 mt-4 text-center co-pointer co-dashboard-card primary-hover-animation"
          (click)="navigateToPipeline()">
          <mat-card-content>
            {{'carrier_dashboard.release_pipeline.tile_name' | translate}}
            <br>
            <div class="d-flex gap-2 justify-content-center align-items-center">
              <span class="d-flex gap-2 justify-content-center align-items-center"
                *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">
                {{dashboardNumbers?.shipments_for_approval}}
                <img src="assets/icon/check-double.svg" />
              </span>
            </div>
            <small>{{ 'carrier_dashboard.tile.release_pipeline.help_text' | translate }}</small>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="card-dashboard col-md-12 col-lg-4 " *ngIf="(usersService.ownUser$ | async)?.permission_alerts_place">
        <mat-card class="mb-4 mt-4 text-center co-pointer co-dashboard-card primary-hover-animation"
          (click)="navigateToAlerts()">
          <mat-card-content>
            {{'carrier_dashboard.warning.tile_name' | translate}}
            <br>
            <div class="d-flex gap-2 justify-content-center align-items-center">
              <span class="d-flex gap-2 justify-content-center align-items-center" style="color: var(--color-yellow);"
                *ngIf="(organizationsService.ownOrg$ | async)?.admin_permission_software_license else noSubscription">{{dashboardNumbers?.shipments_in_alert}}
                <img src="assets/icon/exclamation-triangle.svg" />
              </span>
            </div>
            <small>{{'carrier_dashboard.client_shipment_with_warning' | translate}} </small>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <!-- button container -->
    <div style="width: 300px;" class="container mt-4 mb-4 text-center d-flex flex-column justify-content-center gap-4"
      *ngIf="(usersService.ownUser$ | async)?.permission_new_shipments">
      <button class="btn-primary big green primary-hover-animation" [routerLink]="['/main/carrier/wizard']">
        <mat-icon>add_circle_outline</mat-icon> {{'common.record_shipment' | translate}}
      </button>

      <button class="btn-primary big green primary-hover-animation" [routerLink]="['/main/carrier/maps']">
        <mat-icon>map</mat-icon> {{'common.maps' | translate}}
      </button>
    </div>
  </div>

  <ng-template #noSubscription>
    <img src="assets/Lock-Cargoflow.png" loading="lazy" alt="" width="70px" class="image my-2">
  </ng-template>

</div>