<div class="container-fluid">
  <mat-card class="mt-3" *ngIf="client">
    <mat-card-content>

      <div class="gap-2" style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1><strong class="me-2">{{'common.client' | translate}}:</strong>
          <span *ngIf="client?.company; else noCompany">{{client.company}}</span>
          <ng-template #noCompany>{{client.first_name}} {{client.last_name}}</ng-template>
        </h1>

        <div *ngIf="client">
          <button mat-icon-button color="primary" (click)="editClient(client)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="deleteClient(client)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div>

        <nav class="nav nav-pills nav-justified d-flex justify-content-around my-3">
          <a class="btn-primary dark primary-hover-animation w-25" [routerLinkActive]="['active']"
            routerLink="/main/carrier/clients/{{client.uuid}}/details">{{'common.details' | translate}}</a>
          <a class="btn-primary dark primary-hover-animation w-25" [routerLinkActive]="['active']"
            routerLink="/main/carrier/clients/{{client.uuid}}/shipments">{{'common.shipments' | translate}}</a>
          <a class="btn-primary dark primary-hover-animation w-25" [routerLinkActive]="['active']"
            routerLink="/main/carrier/clients/{{client.uuid}}/integrations">{{'common.integrations' | translate}}</a>
        </nav>

        <div class="mt-2">
          <ul class="co-simple-list" *ngIf="client">
            <li *ngIf="client.company"><strong>{{'common.firma' | translate}}:</strong> {{client.company}}</li>
            <li><strong>{{'common.responsable_person' | translate}}:</strong> {{client.first_name}} {{client.last_name}}</li>
            <hr>
            <li><strong class="me-2">{{'common.phone' | translate}}:</strong> <a href="tel:{{client.phone}}">{{client.phone}}</a></li>
            <li><strong class="me-2">{{'common.mail' | translate}}:</strong> <a href="mailto:{{client.email}}">{{client.email}}</a></li>
            <hr>
            <li><strong class="me-2">{{'common.address' | translate}}:</strong> {{client.address}}</li>
            <li><strong class="me-2">{{'common.locality' | translate}}:</strong> {{client.zip_code}} {{client.location}}</li>
            <hr>
            <li><strong class="me-2">{{'common.customer_since' | translate}}:</strong> {{client.created_at | humanDatePipe}}</li>
            <li><strong class="me-2">{{'common.id' | translate}}:</strong> {{client.uuid}}</li>
            <hr>
            <li *ngIf="client.description">{{'common.remark' | translate}}: {{client.description}}</li>
          </ul>

          <div class="alert alert-info " *ngIf="client.client_object; else noOrganization">
            {{'common.this_customer_is_linked_to_the_organization' | translate}}:
            <b>
              <span
                *ngIf="client.client_object.company_name; else noOrgCompany">{{client.client_object.company_name}}</span>
              <ng-template #noOrgCompany>{{client.client_object.first_name}}
                {{client.client_object.last_name}}</ng-template>
            </b>
          </div>
          <ng-template #noOrganization>
            <div class="alert alert-info ">
              {{'common.this_customer_is_not_linked_to_any_organization' | translate}}
            </div>
          </ng-template>
          <!-- <pre>{{client | json}}</pre> -->
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>
