<div class="container">

  <mat-card class="mt-3">
    <mat-card-content>

      <div class="d-flex flex-column flex-md-row justify-content-between">

        <h1 class="d-flex flex-column flex-md-row text-center gap-2 order-2 order-md-1">
          {{'common.users_in_organization' | translate}}
          <span style="text-decoration: underline;">{{currentOrg}}</span>
        </h1>
        <div class="order-1 order-md-2">
          <button class="d-flex ms-auto" mat-icon-button color="primary" (click)="refresh()">
            <mat-icon class="fs-3">refresh</mat-icon>
          </button>
        </div>
      </div>
      <div class="mt-4 d-flex flex-column flex-sm-row justify-content-around gap-2">
        <button class="green-btn primary-hover-animation d-flex align-items-center gap-2 justify-content-center"
          (click)="onAddDriver()">{{'carrier_dashboard.user-editor-organizations.add_new_driver' | translate}}
          <mat-icon>directions_car</mat-icon>
        </button>
        <button class="green-btn primary-hover-animation d-flex align-items-center gap-2 justify-content-center"
          (click)="onAddUser()">{{'carrier_dashboard.user-editor-organizations.add_new_user' | translate}}
          <mat-icon>supervised_user_circle</mat-icon>
        </button>
      </div>

      <div style="overflow-x:auto;" class="mt-4">
        <table style="width: 100%" class="table table-custom">
          <thead>
            <!-- <td>Avatar</td> -->
            <td>{{'common.user' | translate}}</td>
            <td>{{'common.username' | translate}}</td>
            <td>{{'common.mail' | translate}}</td>
            <td>{{'common.phone' | translate}}</td>
            <!-- <td>Benutzerrollen</td> -->
            <td></td>
          </thead>

          <tbody>
            <tr *ngFor="let user of users">
              <!-- <td>
                                <app-avatar-view [user]="user"></app-avatar-view>\
                            </td> -->
              <td [attr.data-label]="'common.name' | translate">
                <span class="d-flex justify-content-end justify-content-md-start">
                  <mat-icon *ngIf="user.permission_driver_panel" class="user-icon">directions_car</mat-icon><mat-icon
                    *ngIf="!user.permission_driver_panel"
                    class="user-icon">supervised_user_circle</mat-icon>{{user.first_name}} {{user.last_name}}
                </span>

                <small style="color: gray;">{{user.uuid}}</small>
              </td>

              <td [attr.data-label]="'common.username' | translate">{{user.username}}</td>
              <td [attr.data-label]="'common.mail' | translate">{{user.email}}</td>
              <td [attr.data-label]="'common.phone' | translate">{{user.phone}}</td>

              <td>

                <!-- <button mat-icon-button (click)="onDetails(user)">
                                    <mat-icon>details</mat-icon>
                                </button> -->
                <div class="d-flex justify-content-around justify-content-md-end">

                  <button mat-icon-button (click)="onDetails(user)">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button mat-icon-button (click)="onMaps(user)">
                    <mat-icon>maps</mat-icon>
                  </button>

                  <button mat-icon-button (click)="onEditPassword(user)">
                    <mat-icon>lock</mat-icon>
                  </button>

                  <button mat-icon-button (click)="onDeleteUser(user)" [disabled]="user.id == authService.getUserId()">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>

              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </mat-card-content>
  </mat-card>

</div>
